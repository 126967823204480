import React from "react";
//image
import { logo } from "../../img/";
//styles
import styled from "styled-components";

const Logo = ({ style }) => {
  return (
    <StyledLogo style={{ ...style }}>
      <img src={logo} alt="Logo" />
    </StyledLogo>
  );
};

const StyledLogo = styled.div`
  width: 35vw;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media all and (max-width: 1600px) {
    width: 35vw;
  }
  @media all and (max-width: 1144px) {
    width: 50vw;
  }
  @media all and (max-width: 768px) {
    width: 90vw;
  }
`;

export default Logo;
