import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
//components
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ConsentCheckboxes from "./components/ConsentCheckboxes";
//styles
import {
  StyledContact,
  StyledContainer,
  StyledForm,
} from "../styles/jobs/contact";
//files and images
import { kraz } from "../files";
import { rzetelna } from "../img";
//animations
import { motion } from "framer-motion";
import {
  FadeLeftAnimation,
  FadeRightAnimation,
  PagesAnimation,
} from "../styles/Animations";
//languages
import { langContent } from "../languages";

const Contact = ({ language }) => {
  const formSwal = withReactContent(Swal);

  let lang = langContent;
  if (language === "Polish") {
    lang = langContent.Polish;
  } else if (language === "English") {
    lang = langContent.English;
  } else {
    lang = langContent.Russian;
  }

  //form handler
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  let data;

  useEffect(() => {
    setNameError("");
    setEmailError("");
    setSubjectError("");
    setMessageError("");
  }, [language]);

  const NameInputHandler = (event) => {
    setName(event.target.value);
  };
  const CompanyInputHandler = (event) => {
    setCompany(event.target.value);
  };
  const EmailInputHandler = (event) => {
    setEmail(event.target.value);
  };
  const SubjectInputHandler = (event) => {
    setSubject(event.target.value);
  };
  const MessageInputHandler = (event) => {
    setMessage(event.target.value);
  };

  const SubmitHandler = (event) => {
    event.preventDefault();
    let failed = false;

    //check name
    if (name === "") {
      failed = true;
      setNameError(lang.contact.formNameError1);
    } else if (name.length <= 1) {
      failed = true;
      setNameError(lang.contact.formNameError2);
    } else if (
      !/^[a-zA-Zа-яА-Я, ,\-,ł,Ł,ę,Ę,ą,Ą,ó,Ó,ż,Ż,ź,Ź,ć,Ć,ś,Ś,n,Ń,å,Å,ä,Ä,ö,Ö,ü,Ü,ß,ẞ]*$/g.test(
        name
      )
    ) {
      failed = true;
      setNameError(lang.contact.formNameError3);
    } else {
      setNameError("");
    }

    //check email
    if (email === "") {
      failed = true;
      setEmailError(lang.contact.formEmailError1);
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      failed = true;
      setEmailError(lang.contact.formEmailError2);
    } else {
      setEmailError("");
    }

    //check subject
    if (subject === "") {
      failed = true;
      setSubjectError(lang.contact.formSubjectError1);
    } else if (subject.length <= 1) {
      failed = true;
      setSubjectError(lang.contact.formSubjectError2);
    } else {
      setSubjectError("");
    }

    //check message
    if (message === "") {
      failed = true;
      setMessageError(lang.contact.formMessageError1);
    } else if (message.length <= 1) {
      failed = true;
      setMessageError(lang.contact.formMessageError2);
    } else {
      setMessageError("");
    }

    if (failed) {
      return false;
    } else {
      data = {
        name: name,
        company: company,
        email: email,
        subject: subject,
        message: message,
        site: "AgwiPol Jobs",
      };
      setName("");
      setCompany("");
      setEmail("");
      setSubject("");
      setMessage("");
      axios({
        method: "POST",
        url: "/api/contact",
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log("Good request: " + response);
          formSwal.fire({
            title: (
              <h2
                style={{
                  fontFamily: language === "Russian" ? "Nunito" : "Poppins",
                }}
              >
                {lang.index.formSuccessTitle}
              </h2>
            ),
            html: (
              <div
                style={{
                  fontFamily: language === "Russian" ? "Nunito" : "Poppins",
                }}
              >
                {lang.index.formSuccessText}
              </div>
            ),
            icon: "success",
            confirmButtonText: lang.index.formErrorButton,
            scrollbarPadding: false,
            backdrop: `
                rgba(0, 0, 0, 0.4)
                `,
          });
        })
        .catch((error) => {
          console.log("Bad requst: " + error);
          formSwal.fire({
            title: (
              <h2
                style={{
                  fontFamily: language === "Russian" ? "Nunito" : "Poppins",
                }}
              >
                {lang.index.formErrorTitle}
              </h2>
            ),
            html: (
              <div
                style={{
                  fontFamily: language === "Russian" ? "Nunito" : "Poppins",
                }}
              >
                {lang.index.formErrorText}
              </div>
            ),
            icon: "error",
            confirmButtonText: lang.index.formErrorButton,
            confirmButtonColor: "rgb(218, 0, 38)",
            scrollbarPadding: false,
            backdrop: `
                rgba(0, 0, 0, 0.4)
                `,
          });
        });
    }
  };

  const [consent, setConsent] = useState({
    data: false,
    doc: false,
  });

  return (
    <motion.div
      variants={PagesAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Header language={language} />

      <StyledContact className="contact">
        <StyledContainer>
          <motion.div className="left" variants={FadeRightAnimation}>
            <div className="form-container">
              <StyledForm onSubmit={SubmitHandler}>
                <div className="name-company form-inner">
                  <div className="name">
                    <label htmlFor="name">{lang.contact.formName}</label>
                    <input
                      onChange={NameInputHandler}
                      className={nameError.length !== 0 ? "has-error" : ""}
                      value={name}
                      type="text"
                      id="name"
                    />
                    {nameError.length !== 0 && (
                      <span value={nameError} className="error">
                        {nameError}
                      </span>
                    )}
                  </div>
                  <div className="company">
                    <label htmlFor="company">{lang.contact.formCompany}</label>
                    <input
                      onChange={CompanyInputHandler}
                      value={company}
                      type="text"
                      id="company"
                    />
                  </div>
                </div>
                <div className="email form-inner">
                  <label htmlFor="email">{lang.contact.formEmail}</label>
                  <input
                    onChange={EmailInputHandler}
                    className={emailError.length !== 0 ? "has-error" : ""}
                    value={email}
                    type="email"
                    id="email"
                  />
                  {emailError.length !== 0 && (
                    <span value={emailError} className="error">
                      {emailError}
                    </span>
                  )}
                </div>
                <div className="subject form-inner">
                  <label htmlFor="subject">{lang.contact.formSubject}</label>
                  <input
                    onChange={SubjectInputHandler}
                    className={subjectError.length !== 0 ? "has-error" : ""}
                    value={subject}
                    type="text"
                    id="subject"
                  />
                  {subjectError.length !== 0 && (
                    <span value={subjectError} className="error">
                      {subjectError}
                    </span>
                  )}
                </div>
                <div className="message form-inner">
                  <label htmlFor="message">{lang.contact.formMessage}</label>
                  <textarea
                    onChange={MessageInputHandler}
                    className={messageError.length !== 0 ? "has-error" : ""}
                    value={message}
                    placeholder={lang.contact.formMessagePlaceholder}
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                  ></textarea>
                  {messageError.length !== 0 && (
                    <span value={messageError} className="error">
                      {messageError}
                    </span>
                  )}
                </div>
                <ConsentCheckboxes
                  consent={consent}
                  language={language}
                  setConsent={setConsent}
                />
                <div className="button-container">
                  <button
                    disabled={Object.values(consent).some(
                      (value) => value === false
                    )}
                    type="submit"
                  >
                    {lang.contact.formButton}
                  </button>
                </div>
              </StyledForm>
            </div>
          </motion.div>

          <motion.div className="right" variants={FadeLeftAnimation}>
            <div className="info-container">
              <div className="info">
                <h4>AgwiPol Sp. z o.o.</h4>
                <p>NIP: 7010645357</p>
                <p>REGON: 366158708</p>
                <p>KRS: 0000655136</p>
                <Link to={kraz} target="blank">
                  Wpis do KRAZ nr. 18785
                </Link>
                <a
                  href="https://wizytowka.rzetelnafirma.pl/6FPYR0E2"
                  target="blank"
                >
                  <img src={rzetelna} alt="Rzetelna Firma" />
                </a>
              </div>
              <div className="email-info">
                <h4>{lang.contact.email}</h4>
                <a href="mailto:biuro@agwipol.pl">biuro@agwipol.pl</a>
              </div>
              <div className="phone-info">
                <h4>{lang.contact.phones}</h4>
                <p>+48574497249, +48888768031</p>
              </div>
              <div className="address-info">
                <h4>{lang.contact.address}</h4>
                <div className="map">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2393.8042519491264!2d23.13619541583044!3d53.13165937993454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ffc0c7e6294f9%3A0x330562dac2252aae!2sKardyna%C5%82a%20Stefana%20Wyszy%C5%84skiego%202%2F1%2C%2015-888%20Bia%C5%82ystok!5e0!3m2!1sen!2spl!4v1574184674345!5m2!1sen!2spl"
                    frameBorder="0"
                  ></iframe>
                </div>
                <a
                  href="https://www.google.com/maps/place/Kardyna%C5%82a+Stefana+Wyszy%C5%84skiego+2%2F1,+15-888+Bia%C5%82ystok/@53.1316626,23.1361954,17z/data=!3m1!4b1!4m5!3m4!1s0x471ffc0c7e6294f9:0x330562dac2252aae!8m2!3d53.1316594!4d23.1383841"
                  target="blank"
                >
                  ul. Kard. St. Wyszyńskiego 2/1, lok. 303, 15-888 Białystok
                </a>
              </div>
            </div>
          </motion.div>
        </StyledContainer>
      </StyledContact>

      <ScrollToTop />
    </motion.div>
  );
};

export default Contact;
