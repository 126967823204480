import React, { useState } from "react";
import Checkbox from "react-custom-checkbox";
import styled from "styled-components";
import { check } from "../../img";
import { langContent } from "../../languages";

const CookiesPopup = ({ handleClosePopup, language }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [cookies, setCookies] = useState({
    functional: true,
    analytical: true,
    marketing: true,
  });

  let lang = langContent;
  if (language === "Polish") {
    lang = langContent.Polish;
  } else if (language === "English") {
    lang = langContent.English;
  } else {
    lang = langContent.Russian;
  }

  function setConsent(cookiesConsent) {
    const consentMode = {
      functionality_storage: "granted",
      security_storage: "granted",
      ad_storage: cookiesConsent.marketing ? "granted" : "denied",
      analytics_storage: cookiesConsent.analytical ? "granted" : "denied",
      personalization_storage: cookiesConsent.marketing ? "granted" : "denied",
    };
    window.gtag("consent", "update", consentMode);
    localStorage.setItem("consentMode", JSON.stringify(consentMode));
  }

  const handleSaveCookies = () => {
    setConsent(cookies);
    handleClosePopup();
  };

  const handleRejectCookies = () => {
    setCookies({ cookies, analytical: false, marketing: false });
    setConsent({ cookies, analytical: false, marketing: false });
    handleClosePopup();
  };

  return (
    <StyledPopup>
      <h2>{lang.cookies.header}</h2>
      <p>{lang.cookies.paragraph}</p>
      {detailsVisible ? (
        <>
          <div className="details-container">
            <Checkbox
              borderColor="rgb(218, 0, 38)"
              checked={cookies.functional}
              containerStyle={{ cursor: "not-allowed" }}
              disabled
              icon={<img src={check} alt="check" />}
              label={lang.cookies.functionalCheckbox}
              size={25}
              style={{ background: "rgb(218, 0, 38)" }}
            />
            <Checkbox
              borderColor="rgb(218, 0, 38)"
              checked={cookies.analytical}
              containerStyle={{ cursor: "pointer" }}
              icon={<img src={check} alt="check" />}
              label={lang.cookies.analyticalCheckbox}
              onChange={() =>
                setCookies({
                  ...cookies,
                  analytical: !cookies.analytical,
                })
              }
              size={25}
              style={{
                background: cookies.analytical ? "rgb(218, 0, 38)" : "#fff",
              }}
            />
            <Checkbox
              borderColor="rgb(218, 0, 38)"
              checked={cookies.marketing}
              containerStyle={{ cursor: "pointer" }}
              icon={<img src={check} alt="check" />}
              label={lang.cookies.marketingCheckbox}
              onChange={() =>
                setCookies({
                  ...cookies,
                  marketing: !cookies.marketing,
                })
              }
              size={25}
              style={{
                background: cookies.marketing ? "rgb(218, 0, 38)" : "#fff",
              }}
            />
          </div>
          <button onClick={handleSaveCookies}>{lang.cookies.saveBtn}</button>
        </>
      ) : (
        <div className="btn-container">
          <button onClick={handleSaveCookies}>{lang.cookies.acceptBtn}</button>
          <button onClick={handleRejectCookies}>
            {lang.cookies.rejectBtn}
          </button>
          <button onClick={() => setDetailsVisible(true)}>
            {lang.cookies.detailsBtn}
          </button>
        </div>
      )}
    </StyledPopup>
  );
};

const StyledPopup = styled.div`
  -webkit-box-shadow: 0px 0px 50px 0px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 50px 0px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 50px 0px rgba(66, 68, 90, 1);
  background: white;
  border: solid 2px rgb(218, 0, 38);
  left: 50%;
  padding: 20px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  z-index: 11;
  h2 {
    margin-bottom: 20px;
  }
  .details-container {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
  }
  .details-container + button {
    display: block;
    margin: 0 auto;
    padding: 10px;
  }
  .btn-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  button {
    font-size: 1rem;
    width: 12.5rem;
    height: auto;
    padding: 0;
    &:hover {
      color: black;
      border: 2px solid black;
    }
  }

  @media (max-width: 960px) {
    width: 90%;
    max-height: 90%;
    padding: 15px;
    h2 {
      font-size: 1.5rem;
    }
    p {
      height: 250px;
      overflow: scroll;
    }
    .details-container {
      flex-direction: column;
      label {
        margin: 10px 0;
      }
    }
    .btn-container {
      flex-direction: column;
      align-items: center;
    }
    button {
      width: 100%;
      padding: 5px;
      margin: 10px 0;
    }
  }
`;

export default CookiesPopup;
