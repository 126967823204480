import styled from "styled-components";
import { bg, princip } from "../../img";
import { motion } from "framer-motion";

//main section
export const StyledMain = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("${bg}") fixed center no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
  color: white;
  * {
    transition: all 0.3s;
  }
`;

export const StyledMainContainer = styled.div`
  height: 100vh;
  width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media all and (max-width: 1600px) {
    width: 1280px;
  }
  @media all and (max-width: 1144px) {
    width: 90vw;
  }
  @media all and (max-width: 768px) {
    width: 95vw;
  }
`;

export const StyledOffer = styled(motion.div)`
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 2rem;
  padding: 0 2rem;
  #First,
  #Third {
    background: rgb(218, 0, 38);
    width: 30%;
    padding: 3rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      cursor: pointer;
      background: #ae001e;
    }
  }
  #Second {
    background: #212c36;
    border: 1px solid #212c36;
    width: 40%;
    padding: 3rem;
  }
  img:not(.consent-img) {
    width: 120px;
    height: 120px;
    margin-bottom: 2rem;
  }
  h2 {
    letter-spacing: 2px;
    font-weight: 400;
    margin-bottom: 2rem;
  }
  p {
    color: white;
    text-align: center;
    letter-spacing: 0.5px;
  }

  @media all and (max-width: 1600px) {
    bottom: -23rem;
    #First,
    #Third {
      h2 {
        font-size: 1.5rem;
      }
    }
    #Second {
      padding: 1.5rem;
    }
  }

  @media all and (max-width: 1144px) {
    #Second {
      padding: 2.5rem;
    }
  }

  @media all and (max-width: 960px) {
    padding: 0;
    top: 60%;
    flex-direction: column;
    #First,
    #Second,
    #Third {
      padding: 1.5rem;
      width: 100%;
    }
  }
`;

export const StyledForm = styled.form`
  .field {
    margin: 1.5rem 0;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
  }
  #name-input,
  #number-input {
    display: block;
    margin-bottom: 0.5rem;
    width: 100%;
    height: 45px;
    font-size: 1rem;
    line-height: 1.5;
    background: transparent;
    border: 3px rgba(255, 255, 255, 0.6) solid;
    font-weight: 300;
    color: white;
    padding: 6px;
    &:focus {
      border: 3px white solid;
      outline: none;
    }
  }
  .has-error {
    border: 3px solid rgb(218, 0, 38);
  }
  .error {
    color: rgb(218, 0, 38);
  }
  button {
    width: 100%;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 300;
    letter-spacing: 0.2em;
    padding: 5px 0;
    margin: 0.5rem auto 0 auto;
    &:disabled {
      cursor: not-allowed;
      background: transparent;
      color: rgba(255, 255, 255, 0.6);
      border-color: rgba(255, 255, 255, 0.6);
    }
  }
`;

//services section
export const StyledServices = styled.section`
  padding: 4rem 0 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    font-weight: 700;
    color: rgb(218, 0, 38);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%, 3rem);
      width: 5rem;
      height: 0.3rem;
      background: rgb(218, 0, 38);
    }
  }
  @media all and (max-width: 768px) {
    padding: 2rem 0;
    h2::after {
      top: 50%;
    }
  }
`;

export const StyledServicesContainer = styled.div`
  overflow-x: hidden;
  height: 100%;
  width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .offer-container {
    margin-top: 2rem;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
    .first,
    .second,
    .third {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
    }
    .img-container {
      width: 7rem;
      height: 7rem;
      margin-bottom: 2rem;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    h2 {
      margin-bottom: 2.5rem;
    }
  }

  @media all and (max-width: 1600px) {
    width: 1000px;
    margin-top: 30%;
    .offer-container {
      margin: 1rem 0;
      h2 {
        font-size: 1.8rem;
      }
    }
  }

  @media all and (max-width: 1144px) {
    width: 90vw;
  }

  @media all and (max-width: 960px) {
    margin-top: 100%;
  }

  @media all and (max-width: 768px) {
    width: 95vw;
    margin-top: 125%;
    h2 {
      margin-bottom: 2rem;
    }
    .offer-container {
      margin: 1.5rem 0;
      flex-direction: column;
      .img-container {
        width: 5.5rem;
        height: 5.5rem;
      }
      .first,
      .second,
      .third {
        padding: 1rem;
        h2 {
          font-size: 1.5rem;
          font-weight: 500;
        }
      }
    }
  }

  @media (max-width: 500px) {
    margin-top: 400%;
  }

  @media (max-width: 340px) {
    margin-top: 450%;
  }

  @media (max-width: 280px) {
    margin-top: 500%;
  }
`;

//principles section
export const StyledPrinciples = styled.section`
  padding: 3rem 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("${princip}") fixed center no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const StyledPrinciplesContainer = styled.div`
  overflow-x: hidden;
  height: 100%;
  width: 1400px;
  display: flex;
  flex-direction: column;
  h2 {
    font-weight: 700;
    color: rgb(218, 0, 38);
    position: relative;
    margin-bottom: 3rem;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%, 3rem);
      width: 5rem;
      height: 0.3rem;
      background: rgb(218, 0, 38);
    }
  }
  p {
    color: white;
  }

  .intro {
    margin-bottom: 4rem;
    padding: 0 2rem;
  }

  .principles {
    display: flex;
    overflow: hidden;
    .first,
    .second,
    .third {
      flex: 1;
      padding: 1rem 2rem;
    }
    span {
      width: 50px;
      height: 50px;
      background: rgb(218, 0, 38);
      color: white;
      line-height: 50px;
      font-weight: 300;
      display: block;
      text-align: center;
      font-size: 20px;
      border-radius: 50%;
      margin: 0 auto;
    }
    h3 {
      font-size: 1.4rem;
      margin: 2rem 0;
    }
  }

  @media all and (max-width: 1600px) {
    width: 1000px;
    .intro {
      margin-bottom: 2rem;
    }
    .principles {
      h3 {
        margin: 1.5rem 0;
      }
    }
  }

  @media all and (max-width: 1144px) {
    width: 90vw;
  }

  @media all and (max-width: 768px) {
    width: 95vw;
    .intro {
      padding: 0 1rem;
    }
    .principles {
      flex-direction: column;
      .first,
      .second,
      .third {
        padding: 1rem;
      }
    }
  }
`;
