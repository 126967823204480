export const MainPageAnimation = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const PagesAnimation = {
  hidden: {
    opacity: 1,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
  exit: { opacity: 1 },
};

export const SlideAnimation = {
  hidden: {
    opacity: 0,
    x: "-100%",
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      type: "spring",
      stiffness: 150,
    },
  },
  exit: {
    opacity: 0,
    x: "100%",
    transition: {
      duration: 0.3,
    },
  },
};

export const FadeInAnimation = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.25,
    },
  },
};

export const FadeUpAnimation = {
  hidden: {
    opacity: 0,
    y: "100%",
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
    },
  },
};

export const FadeDownAnimation = {
  hidden: {
    opacity: 0,
    y: "-100%",
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
    },
  },
};
export const FadeRightAnimation = {
  hidden: {
    opacity: 0,
    x: "-100%",
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.25,
    },
  },
};

export const FadeLeftAnimation = {
  hidden: {
    opacity: 0,
    x: "100%",
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.25,
    },
  },
};

export const ScaleInAnimation = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      // stiffness: 100,
    },
  },
};

export const DropdownAnimation = {
  hidden: {
    display: "none",
  },
  show: {
    display: "block",
    transition: {
      when: "beforeChildren",
    },
  },
  exit: {
    display: "none",
    transition: {
      when: "afterChildren",
    },
  },
};

export const MenuAnimation = {
  hidden: {
    opacity: 0,
    y: -500,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    y: -500,
    transition: {
      duration: 0.3,
    },
  },
};

export const BurgerMenuAnimation = {
  hidden: {
    opacity: 0,
    visibility: "hidden",
    scale: 5,
    transition: {
      duration: 0.5,
    },
  },
  show: {
    opacity: 1,
    visibility: "visible",
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    visibility: "hidden",
    scale: 50,
    transition: {
      duration: 0.5,
    },
  },
};
