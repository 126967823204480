import React from "react";
//components
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
//styles and images
import {
  StyledServices,
  StyledContainer,
  StyledGraphic,
  StyledOffer,
} from "../styles/jobs/employers";
import { arrow, graphEN, graphPL, graphRU, offer } from "../img";
//animations
import { motion } from "framer-motion";
import {
  PagesAnimation,
  FadeInAnimation,
  FadeRightAnimation,
  FadeLeftAnimation,
  ScaleInAnimation,
} from "../styles/Animations";
import { AnimateInView } from "./components/AnimateInView";
//language
import { langContent } from "../languages";

const Employers = (props) => {
  let lang = langContent;
  let langImg;
  if (props.language === "Polish") {
    lang = langContent.Polish;
    langImg = graphPL;
  } else if (props.language === "English") {
    lang = langContent.English;
    langImg = graphEN;
  } else {
    lang = langContent.Russian;
    langImg = graphRU;
  }

  const [graphElement1, graphControls1] = AnimateInView();
  const [graphElement2, graphControls2] = AnimateInView();
  const [offerElement, offerControls] = AnimateInView();

  return (
    <motion.div
      variants={PagesAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Header language={props.language} />

      <StyledServices>
        <StyledContainer>
          <h2>{lang.employers.servicesHeader}</h2>
          <motion.div className="container" variants={FadeInAnimation}>
            <motion.div className="first" variants={ScaleInAnimation}>
              <div className="head">
                <h3>
                  <pre>{lang.employers.servicesFirstHeader}</pre>
                </h3>
              </div>
              <div className="body">
                <ul>
                  <li>{lang.employers.servicesFirstLi1}</li>
                  <li>{lang.employers.servicesFirstLi2}</li>
                  <li>{lang.employers.servicesFirstLi3}</li>
                  <li className="inner">{lang.employers.servicesFirstLi4}</li>
                  <li className="inner">{lang.employers.servicesFirstLi5}</li>
                  <li className="inner">{lang.employers.servicesFirstLi6}</li>
                  <li className="inner">{lang.employers.servicesFirstLi7}</li>
                  <li className="inner">{lang.employers.servicesFirstLi8}</li>
                  <li>{lang.employers.servicesFirstLi9}</li>
                </ul>
              </div>
            </motion.div>
            <motion.div className="arrow" variants={FadeRightAnimation}>
              <img src={arrow} alt="Arrow" />
            </motion.div>
            <motion.div className="second" variants={ScaleInAnimation}>
              <div className="head">
                <h3>
                  <pre>{lang.employers.servicesSecondHeader}</pre>
                </h3>
              </div>
              <div className="body">
                <ul>
                  <li>{lang.employers.servicesSecondLi1}</li>
                  <li className="inner">{lang.employers.servicesSecondLi2}</li>
                  <li className="inner">{lang.employers.servicesSecondLi3}</li>
                  <li>{lang.employers.servicesSecondLi4}</li>
                  <li>{lang.employers.servicesSecondLi5}</li>
                  <li>{lang.employers.servicesSecondLi6}</li>
                </ul>
              </div>
            </motion.div>
            <motion.div className="arrow" variants={FadeRightAnimation}>
              <img src={arrow} alt="Arrow" />
            </motion.div>
            <motion.div className="third" variants={ScaleInAnimation}>
              <div className="head">
                <h3>
                  <pre>{lang.employers.servicesThirdHeader}</pre>
                </h3>
              </div>
              <div className="body">
                <p>{lang.employers.servicesThirdParagraph}</p>
                <ul>
                  <li>{lang.employers.servicesThirdLi1}</li>
                  <li>{lang.employers.servicesThirdLi2}</li>
                  <li>{lang.employers.servicesThirdLi3}</li>
                  <li>{lang.employers.servicesThirdLi4}</li>
                  <li>{lang.employers.servicesThirdLi5}</li>
                </ul>
              </div>
            </motion.div>
          </motion.div>
        </StyledContainer>
      </StyledServices>

      <StyledGraphic>
        <div className="img-container">
          <motion.img
            src={langImg}
            alt="Graphic"
            ref={graphElement1}
            variants={ScaleInAnimation}
            animate={graphControls1}
          />
        </div>
        <motion.div
          className="statistics"
          ref={graphElement2}
          variants={FadeInAnimation}
          animate={graphControls2}
        >
          <motion.div className="content" variants={FadeLeftAnimation}>
            <h3>7+</h3>
            <h4>{lang.employers.graphFirst}</h4>
          </motion.div>
          <motion.div className="content" variants={FadeLeftAnimation}>
            <h3>1000+</h3>
            <h4>{lang.employers.graphSecond}</h4>
          </motion.div>
          <motion.div className="content" variants={FadeLeftAnimation}>
            <h3>8</h3>
            <h4>{lang.employers.graphFourth}</h4>
          </motion.div>
        </motion.div>
      </StyledGraphic>

      <StyledOffer>
        <StyledContainer>
          <p style={{ marginBottom: "1.5rem" }}>
            {lang.employers.offerParagraph1}
          </p>
          <p>{lang.employers.offerParagraph2}</p>
          <h2>{lang.employers.offerHeader}</h2>
          <motion.div
            className="offer-container"
            ref={offerElement}
            variants={FadeInAnimation}
            animate={offerControls}
          >
            <div className="left">
              <ul>
                <motion.li variants={FadeRightAnimation}>
                  {lang.employers.offerLi1}
                </motion.li>
                <motion.li variants={FadeRightAnimation}>
                  {lang.employers.offerLi2}
                </motion.li>
                <motion.li variants={FadeRightAnimation}>
                  {lang.employers.offerLi3}
                </motion.li>
                <motion.li variants={FadeRightAnimation}>
                  {lang.employers.offerLi4}
                </motion.li>
                <motion.li variants={FadeRightAnimation}>
                  {lang.employers.offerLi5}
                </motion.li>
                <motion.li variants={FadeRightAnimation}>
                  {lang.employers.offerLi6}
                </motion.li>
              </ul>
            </div>
            <div className="right">
              <img src={offer} alt="Offer" />
            </div>
          </motion.div>
          <h2>{lang.employers.offerSubTitle}</h2>
        </StyledContainer>
      </StyledOffer>

      <ScrollToTop />
    </motion.div>
  );
};

export default Employers;
