import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledContact = styled(motion.section)`
  background: #f8f9fa;
  display: flex;
  justify-content: center;
  padding: 4rem 0;
`;

export const StyledContainer = styled.div`
  width: 1400px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  overflow-x: hidden;
  .left,
  .right {
    background: white;
    padding: 4rem;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    flex: 1.5;
    margin-right: 2rem;
  }
  .info-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    h4 {
      color: #212529;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    p,
    a {
      line-height: 1.7;
      font-weight: 300;
      font-size: 1.15rem;
      color: rgb(218, 0, 38);
      margin-bottom: 2px;
    }
    a:hover {
      text-decoration: underline;
    }
    .info {
      img {
        display: block;
        width: 50%;
      }
    }
    iframe {
      width: 100%;
      height: 10rem;
    }
  }
  .form-container {
    width: 100%;
  }
  @media all and (max-width: 1600px) {
    width: 80vw;
  }
  @media all and (max-width: 1444px) {
    width: 90vw;
    .left,
    .right {
      padding: 2rem;
    }
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    .left {
      padding: 2rem 1.5rem;
      margin: 0 0 2rem 0;
    }
    .right {
      padding: 2rem 1.5rem;
      .info-container {
        align-items: center;
        .info,
        .email-info,
        .phone-info,
        .address-info,
        p {
          text-align: center;
        }
        .info,
        .email-info,
        .phone-info {
          margin-bottom: 1rem;
        }
        img {
          margin: auto;
        }
        iframe {
          height: 15rem;
        }
      }
    }
  }
`;

export const StyledForm = styled.form`
  .form-inner {
    margin-bottom: 1rem;
  }
  .name-company {
    display: flex;
    flex-wrap: wrap;
    .name {
      flex: 1;
      margin-right: 1rem;
    }
    .company {
      flex: 1;
      margin-left: 1rem;
    }
  }
  label {
    display: block;
    line-height: 1.7;
    font-weight: 300;
    font-size: 1rem;
    color: #212529;
  }
  input,
  textarea {
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    padding: 0.6rem;
    margin: 0.5rem 0;
    outline: none;
    &:focus {
      border: 1px solid rgb(218, 0, 38);
    }
  }
  .has-error {
    background-color: rgba(218, 0, 38, 0.1);
  }
  .error {
    color: rgb(218, 0, 38);
  }
  .button-container {
    margin-top: 1rem;
  }
  button {
    font-size: 1rem;
    letter-spacing: 0.2em;
    width: 12.5rem;
    height: auto;
    padding: 0.6rem;
    border: 2px solid rgb(218, 0, 38);
    &:hover {
      color: black;
      border: 2px solid black;
    }
    &:disabled {
      cursor: not-allowed;
      background: transparent;
      color: grey;
      border-color: grey;
    }
  }
  @media all and (max-width: 768px) {
    .form-inner {
      margin-bottom: 1.5rem;
    }
    .name-company {
      flex-direction: column;
      .name {
        margin: 0 0 1.5rem;
      }
      .company {
        margin: 0;
      }
    }
    textarea {
      height: 10rem;
    }
    .button {
      text-align: center;
    }
  }
`;
