import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledServices = styled(motion.section)`
  display: flex;
  justify-content: center;
  padding: 4rem 0;
  @media all and (max-width: 1600px) {
    padding: 2.5rem 0;
  }
`;

export const StyledContainer = styled.div`
  width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-weight: 700;
    color: rgb(218, 0, 38);
    position: relative;
    margin-bottom: 5rem;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%, 3rem);
      width: 5rem;
      height: 0.25rem;
      background: rgb(218, 0, 38);
    }
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8;
    text-transform: uppercase;
    padding: 0.5rem;
  }

  .container {
    display: flex;
    align-items: stretch;
    .first,
    .second,
    .third {
      flex: 1;
      background: #f0f0f0;
      border-radius: 1rem;
    }
    .arrow {
      display: inline-block;
      width: 2rem;
      z-index: 1;
      position: relative;
      top: 2.5rem;
    }
    .head {
      background: rgb(218, 0, 38);
      border-radius: 1rem 1rem 0 0;
    }
    .body {
      padding: 1rem 2rem;
      ul,
      li,
      p {
        line-height: 1.7;
        color: #212c36;
        font-weight: 300;
        font-size: 1.1rem;
        text-align: justify;
      }
      li {
        list-style: none;
        &:before {
          content: "✓";
          color: rgb(218, 0, 38);
          font-weight: bold;
          display: inline-block;
          width: 1em;
          font-size: 20px;
          margin-left: -1em;
        }
      }
      .inner {
        margin-left: 25px;
        &:before {
          content: "•";
          color: rgb(218, 0, 38);
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
  @media all and (max-width: 1600px) {
    width: 1000px;
    h2 {
      margin-bottom: 3rem;
    }
  }
  @media all and (max-width: 1144px) {
    width: 90vw;
    h3 {
      font-size: 1.2rem;
    }
  }
  @media all and (max-width: 768px) {
    width: 95vw;
    h2 {
      font-size: 1.5rem;
      &:after {
        width: 5rem;
        transform: translate(-50%, 2.5rem);
      }
    }
    .container {
      flex-direction: column;
      align-items: center;
      .first,
      .second,
      .third {
        width: 85%;
        h3 {
          font-size: 1.3rem;
        }
        li {
          font-size: 1rem;
        }
      }
      .arrow {
        top: 0.5rem;
        img {
          transform: rotate(90deg);
        }
      }
    }
  }
`;

export const StyledGraphic = styled(motion.section)`
  min-height: 400px;
  background: #f0f0f0;
  position: relative;
  display: flex;
  align-items: center;
  .img-container {
    flex: 1;
    background: #212c36;
    text-align: right;
    height: 400px;
    width: 50%;
    position: absolute;
    bottom: 0;
    transform: translateY(30%);
    border-radius: 0 1rem 1rem 0;
    img {
      height: 100%;
    }
  }
  .statistics {
    overflow: hidden;
    flex: 1;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    right: 0;
    width: 60%;
    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }
    h3 {
      flex: 1;
      text-align: right;
      font-size: 3rem;
      font-weight: 700;
      color: rgb(218, 0, 38);
      margin-right: 2rem;
    }
    h4 {
      flex: 2;
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 1.5;
      color: #212529;
    }
  }
  @media all and (max-width: 1600px) {
    .statistics {
      h3 {
        font-size: 2.4rem;
      }
    }
  }
  @media all and (max-width: 1144px) {
    .img-container {
      transform: translateY(3rem);
      width: 30%;
      img {
        width: 100%;
      }
    }
    .statistics {
      width: 50%;
      h3 {
        font-size: 2.2rem;
      }
      h4 {
        font-size: 1rem;
      }
    }
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    height: 100vh;
    .img-container {
      transform: translateY(0);
      width: 100%;
      height: 50%;
      border-radius: 0;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
    .statistics {
      width: 100%;
      height: 50%;
      h3 {
        font-size: 2rem;
      }
    }
  }
`;

export const StyledOffer = styled(motion.section)`
  padding: 10rem 0 3rem 0;
  display: flex;
  justify-content: center;
  h2 {
    margin: 4rem;
  }
  p,
  li {
    color: #4d4d4d;
    font-weight: 300;
    font-size: 1.2rem;
    text-align: justify;
    line-height: 1.7;
  }
  .offer-container {
    display: flex;
    align-items: stretch;
    .left {
      flex: 1;
      ul {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 1rem;
        li {
          list-style-type: none;
          &:before {
            content: "✓";
            color: rgb(218, 0, 38);
            font-weight: bold;
            display: inline-block;
            width: 1em;
            font-size: 20px;
            margin-left: -1em;
          }
        }
      }
    }
    .right {
      flex: 1;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 1rem;
        -webkit-box-shadow: 15px 15px 20px 5px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 15px 15px 20px 5px rgba(0, 0, 0, 0.4);
        box-shadow: 15px 15px 20px 5px rgba(0, 0, 0, 0.4);
      }
    }
  }
  @media all and (max-width: 1144px) {
    .offer-container .left {
      flex: 1.5;
      padding-right: 1rem;
    }
  }
  @media all and (max-width: 768px) {
    padding-top: 2rem;
    h2 {
      margin: 2.5rem;
    }
    p,
    li {
      font-size: 1rem;
    }
    .offer-container {
      flex-direction: column;
      .left {
        padding: 0;
        ul {
          padding: 0 1.5rem;
        }
      }
      .right {
        display: none;
      }
    }
  }
`;
