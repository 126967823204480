import React, { useState, useEffect } from "react";
//image and styles
import { logo } from "../../img";
import styled from "styled-components";

const ScrollBtn = () => {
  const [position, setPosition] = useState(-20);

  useEffect(() => {
    window.onscroll = () => {
      if (window.innerWidth >= 768) {
        window.pageYOffset > 300 ? setPosition(0) : setPosition(-100);
      }
    };
  }, []);

  const ScrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <Scroll
      onClick={ScrollToTop}
      style={{ transform: `translateX(${position}vw)` }}
    >
      <img src={logo} alt="Logo" />
    </Scroll>
  );
};

const Scroll = styled.div`
  cursor: pointer;
  transition: 1s;
  width: 10vw;
  height: 8vh;
  background: #212c36;
  padding: 0 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  position: fixed;
  top: 0;
  left: 2vw;
  z-index: 5;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  @media all and (max-width: 1366px) {
    left: 0.8vw;
  }
`;

export default ScrollBtn;
