import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
//styles and animations
import { motion } from "framer-motion";
import {
  StyledMain,
  StyledMainContainer,
  StyledOffer,
  StyledForm,
  StyledServices,
  StyledServicesContainer,
  StyledPrinciples,
  StyledPrinciplesContainer,
} from "../styles/jobs";
import {
  MainPageAnimation,
  FadeUpAnimation,
  FadeDownAnimation,
  FadeInAnimation,
  FadeRightAnimation,
  FadeLeftAnimation,
} from "../styles/Animations";
import { AnimateInView } from "./components/AnimateInView";
//images
import { briefcase, coordination, documents, search, workers } from "../img";
//components
import Logo from "./components/Logo";
import ScrollBtn from "./components/ScrollBtn";
import ScrollToTop from "./components/ScrollToTop";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//language
import { langContent } from "../languages";
import ConsentCheckboxes from "./components/ConsentCheckboxes";

const JobsHome = ({ language }) => {
  const history = useHistory();
  const formSwal = withReactContent(Swal);

  //animation controls
  const [servicesElement, servicesControls] = AnimateInView();
  const [principlesElement1, principlesControls1] = AnimateInView();
  const [principlesElement2, principlesControls2] = AnimateInView();

  //logo margin
  const setLogoMargin = () => {
    if (window.innerWidth < 1600) {
      return "15rem";
    }
    return "25rem";
  };

  //language check
  let lang = langContent;
  if (language === "Polish") {
    lang = langContent.Polish;
  } else if (language === "English") {
    lang = langContent.English;
  } else {
    lang = langContent.Russian;
  }

  //form handler
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [number, setNumber] = useState("");
  const [numberError, setNumberError] = useState("");
  let data;

  useEffect(() => {
    setName("");
    setNameError("");
    setNumber("");
    setNumberError("");
  }, [language]);

  const NameInputHandler = (event) => {
    setName(event.target.value);
  };
  const NumberInputHandler = (event) => {
    if (/^-?\d*[+-]?\d*$/.test(event.target.value)) {
      setNumber(event.target.value);
    }
  };

  const SubmitHandler = (event) => {
    event.preventDefault();
    let failed = false;
    //check name
    if (name === "") {
      failed = true;
      setNameError(lang.index.formNameErrorLength1);
    } else if (name.length <= 1) {
      failed = true;
      setNameError(lang.index.formNameErrorLength2);
    } else if (
      !/^[a-zA-Zа-яА-Я, ,\-,ł,Ł,ę,Ę,ą,Ą,ó,Ó,ż,Ż,ź,Ź,ć,Ć,ś,Ś,n,Ń,å,Å,ä,Ä,ö,Ö,ü,Ü,ß,ẞ]*$/g.test(
        name
      )
    ) {
      failed = true;
      setNameError(lang.index.formNameErrorLetters);
    } else {
      setNameError("");
    }

    //check phone number
    if (number === "") {
      failed = true;
      setNumberError(lang.index.formNumberErrorLength1);
    } else if (number.length <= 6) {
      failed = true;
      setNumberError(lang.index.formNumberErrorLength2);
    } else if (!/^[+]\d/g.test(number)) {
      failed = true;
      setNumberError(lang.index.formNumberErrorFormat);
    } else {
      setNumberError("");
    }

    if (failed) {
      return false;
    } else {
      data = { name: name, phone: number, site: "AgwiPol Jobs" };
      setName("");
      setNumber("");
      axios({
        method: "POST",
        url: "/api/callback",
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log("Good request: " + response);
          formSwal.fire({
            title: (
              <h2
                style={{
                  fontFamily: language === "Russian" ? "Nunito" : "Poppins",
                }}
              >
                {lang.index.formSuccessTitle}
              </h2>
            ),
            html: (
              <div
                style={{
                  fontFamily: language === "Russian" ? "Nunito" : "Poppins",
                }}
              >
                {lang.index.formSuccessText}
              </div>
            ),
            icon: "success",
            confirmButtonText: lang.index.formErrorButton,
            scrollbarPadding: false,
            backdrop: `
                rgba(0, 0, 0, 0.4)
                `,
          });
        })
        .catch((error) => {
          console.log("Bad requst: " + error);
          formSwal.fire({
            title: (
              <h2
                style={{
                  fontFamily: language === "Russian" ? "Nunito" : "Poppins",
                }}
              >
                {lang.index.formErrorTitle}
              </h2>
            ),
            html: (
              <div
                style={{
                  fontFamily: language === "Russian" ? "Nunito" : "Poppins",
                }}
              >
                {lang.index.formErrorText}
              </div>
            ),
            icon: "error",
            confirmButtonText: lang.index.formErrorButton,
            confirmButtonColor: "rgb(218, 0, 38)",
            scrollbarPadding: false,
            backdrop: `
                rgba(0, 0, 0, 0.4)
                `,
          });
        });
    }
  };

  const [consent, setConsent] = useState({
    data: false,
    doc: false,
  });

  return (
    <motion.div
      variants={MainPageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <StyledMain>
        <ScrollBtn />
        <StyledMainContainer>
          <Logo style={{ marginBottom: `${setLogoMargin()}` }} />
          <StyledOffer variants={FadeInAnimation}>
            <div
              id="First"
              onClick={() => {
                history.push("/employees");
              }}
            >
              <div>
                <img src={workers} alt="Workers" />
                <h2>{lang.index.offerFirst}</h2>
                <p>{lang.index.subOfferFirst}</p>
              </div>
            </div>
            <div id="Second">
              <h2>{lang.index.formTitle}</h2>
              <div className="form-container">
                <StyledForm onSubmit={SubmitHandler}>
                  <div className="field">
                    <label htmlFor="name">{lang.index.formName}</label>
                    <input
                      className={nameError.length !== 0 ? "has-error" : ""}
                      name="Name"
                      id="name-input"
                      onChange={NameInputHandler}
                      placeholder={lang.index.formNamePlaceholder}
                      type="text"
                      value={name}
                    />
                    {nameError.length !== 0 && (
                      <span value={nameError} className="error">
                        {nameError}
                      </span>
                    )}
                  </div>
                  <div className="field">
                    <label htmlFor="phone">{lang.index.formNumber}</label>
                    <input
                      className={numberError.length !== 0 ? "has-error" : ""}
                      name="Phone"
                      id="number-input"
                      onChange={NumberInputHandler}
                      placeholder={lang.index.formNumberPlaceholder}
                      type="text"
                      value={number}
                    />
                    {numberError.length !== 0 && (
                      <span value={numberError} className="error">
                        {numberError}
                      </span>
                    )}
                  </div>
                  <ConsentCheckboxes
                    consent={consent}
                    language={language}
                    setConsent={setConsent}
                  />
                  <button
                    disabled={Object.values(consent).some((el) => el === false)}
                    type="submit"
                  >
                    {lang.index.formButton}
                  </button>
                </StyledForm>
              </div>
            </div>
            <div
              id="Third"
              onClick={() => {
                history.push("/employers");
              }}
            >
              <div>
                <img src={briefcase} alt="Case" />
                <h2>{lang.index.offerThird}</h2>
                <p>{lang.index.subOfferThird}</p>
              </div>
            </div>
          </StyledOffer>
        </StyledMainContainer>
      </StyledMain>

      <StyledServices>
        <StyledServicesContainer>
          <h2>{lang.index.servicesTitle}</h2>
          <motion.div
            className="offer-container"
            ref={servicesElement}
            variants={FadeInAnimation}
            animate={servicesControls}
          >
            <motion.div className="first" variants={FadeRightAnimation}>
              <div className="img-container">
                <img src={search} alt="Staff recruitment" />
              </div>
              <h2>{lang.index.servicesFirstHeader}</h2>
              <p>{lang.index.servicesFirstParagraph}</p>
            </motion.div>
            <motion.div className="second" variants={FadeUpAnimation}>
              <div className="img-container">
                <img src={documents} alt="Documents" />
              </div>
              <h2>{lang.index.servicesSecondHeader}</h2>
              <p>{lang.index.servicesSecondParagraph}</p>
            </motion.div>
            <motion.div className="third" variants={FadeLeftAnimation}>
              <div className="img-container">
                <img src={coordination} alt="Coordination" />
              </div>
              <h2>{lang.index.servicesThirdHeader}</h2>
              <p>{lang.index.servicesThirdParagraph}</p>
            </motion.div>
          </motion.div>
        </StyledServicesContainer>
      </StyledServices>

      <StyledPrinciples>
        <StyledPrinciplesContainer>
          <div className="intro">
            <h2>{lang.index.principlesTitle}</h2>
            <motion.p
              ref={principlesElement1}
              variants={FadeInAnimation}
              animate={principlesControls1}
            >
              {lang.index.principlesParagraph}
            </motion.p>
          </div>
          <div className="principles-container">
            <h2>{lang.index.principlesSubTitle}</h2>
            <motion.div
              className="principles"
              ref={principlesElement2}
              variants={FadeInAnimation}
              animate={principlesControls2}
            >
              <motion.div className="first" variants={FadeRightAnimation}>
                <span>1</span>
                <h3>{lang.index.principlesFirstHeader}</h3>
                <p>{lang.index.principlesFirstParagraph}</p>
              </motion.div>
              <motion.div className="second" variants={FadeDownAnimation}>
                <span>2</span>
                <h3>{lang.index.principlesSecondHeader}</h3>
                <p>{lang.index.principlesSecondParagraph}</p>
              </motion.div>
              <motion.div className="third" variants={FadeLeftAnimation}>
                <span>3</span>
                <h3>{lang.index.principlesThirdHeader}</h3>
                <p>{lang.index.principlesThirdParagraph}</p>
              </motion.div>
            </motion.div>
          </div>
        </StyledPrinciplesContainer>
      </StyledPrinciples>

      <ScrollToTop />
    </motion.div>
  );
};

export default JobsHome;
