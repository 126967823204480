import React from "react";
import { Link } from "react-router-dom";
//styles
import styled from "styled-components";
//files and images
import { kraz, privacyPolicy } from "../../files";
import { fb, insta, rzetelna } from "../../img";
//languages
import { langContent } from "../../languages";

const Footer = (props) => {
  let lang = langContent;
  if (props.language === "Polish") {
    lang = langContent.Polish;
  } else if (props.language === "English") {
    lang = langContent.English;
  } else {
    lang = langContent.Russian;
  }

  return (
    <StyledFooter>
      <StyledFooterContainer>
        <div className="columns-container">
          <div className="firstCol">
            <h3>{lang.footer.linksTitle}</h3>
            <Link to="/employees">{lang.nav.employees}</Link>
            <Link to="/employers">{lang.nav.employers}</Link>
            <Link target="blank" to={privacyPolicy}>
              {lang.nav.privacyPolicy}
            </Link>
            <Link to="/contact">{lang.nav.contact}</Link>
            <div className="social">
              <a
                href="https://www.facebook.com/profile.php?id=61550603774103"
                target="blank"
              >
                <img src={fb} alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/agwi_pol/" target="blank">
                <img src={insta} alt="instagram" />
              </a>
            </div>
          </div>
          <div className="secondCol">
            <h3>{lang.footer.infoTitle}</h3>
            <h4 id="title">AgwiPol Sp. z o.o.</h4>
            <p>NIP: 7010645357</p>
            <p>REGON: 366158708</p>
            <p>KRS: 0000655136</p>
            <Link to={kraz} target="blank">
              Wpis do KRAZ nr. 18785
            </Link>
            <a
              href="https://wizytowka.rzetelnafirma.pl/6FPYR0E2"
              target="blank"
            >
              <img src={rzetelna} alt="Rzetelna Firma" />
            </a>
          </div>
          <div className="thirdCol">
            <h3>{lang.footer.contactsTitle}</h3>
            <div className="address-container">
              <h4>{lang.contact.address}</h4>
              <a
                href="https://www.google.com/maps/place/Kardyna%C5%82a+Stefana+Wyszy%C5%84skiego+2%2F1,+15-888+Bia%C5%82ystok/@53.1316626,23.1361954,17z/data=!3m1!4b1!4m5!3m4!1s0x471ffc0c7e6294f9:0x330562dac2252aae!8m2!3d53.1316594!4d23.1383841"
                target="blank"
              >
                ul. Kard. St. Wyszyńskiego 2/1, lok. 303, <br />
                15-888 Białystok
              </a>
            </div>
            <div className="phone-container">
              <h4>{lang.contact.phones}</h4>
              <p>+48 574497249</p>
              <p>+48 888768031</p>
            </div>
            <div className="email-container">
              <h4>{lang.contact.email}</h4>
              <a href="mailto:biuro@agwipol.pl">biuro@agwipol.pl</a>
            </div>
            <div className="mobile-container">
              <h4>Linki</h4>
              <a
                href="https://www.facebook.com/profile.php?id=61550603774103"
                target="blank"
              >
                Facebook
              </a>
              <a href="https://www.instagram.com/agwi_pol/" target="blank">
                Instagram
              </a>
              <a href={privacyPolicy} target="blank">
                {lang.nav.privacyPolicy}
              </a>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>{`Copyright © ${new Date().getFullYear()} All rights reserved`}</p>
        </div>
      </StyledFooterContainer>
    </StyledFooter>
  );
};

//styles
const StyledFooter = styled.footer`
  background-color: #212c36;
  display: flex;
  justify-content: center;
  * {
    transition: all 0.3s;
  }
`;

const StyledFooterContainer = styled.div`
  height: 100%;
  width: 1400px;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 1rem;
    text-align: left;
    margin-bottom: 1rem;
  }
  #title {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  p,
  a {
    font-size: 1rem;
    color: #999999;
    &:hover {
      color: rgb(218, 0, 38);
    }
  }
  .columns-container {
    height: 100%;
    padding: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    p:hover {
      cursor: default;
    }
    .firstCol,
    .secondCol,
    .thirdCol {
      flex: 1;
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .firstCol {
      .social {
        display: flex;
        align-items: center;
        a {
          width: 3rem;
          height: 3rem;
          margin-right: 1.5rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:hover {
              transform: scale(1.3);
              -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
              box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
    .secondCol {
      img {
        width: 50%;
        object-fit: contain;
      }
    }
    .thirdCol {
      h4 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
      .phone-container {
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        p {
          margin: 0.2rem 0;
        }
      }
      .mobile-container {
        display: none;
      }
    }
  }
  .copyright {
    width: 100%;
    padding: 1rem 0;
    border-top: 1px solid #999999;
    p {
      text-align: center;
      &:hover {
        cursor: default;
        color: #999999;
      }
    }
  }
  @media all and (max-width: 1600px) {
    width: 1000px;
  }
  @media all and (max-width: 1144px) {
    width: 90vw;
  }
  @media all and (max-width: 768px) {
    width: 95vw;
    .columns-container {
      padding: 1.5rem 0;
      flex-direction: column;
      justify-content: center;
      .firstCol,
      .secondCol {
        display: none;
      }
      .thirdCol {
        padding: 0 1rem;
        align-items: center;
        h3 {
          font-size: 1.5rem;
        }
        .mobile-container {
          display: flex;
          margin-top: 1rem;
          flex-direction: column;
          a {
            margin: 0.2rem 0;
          }
        }
        .address-container,
        .email-container,
        .phone-container,
        .mobile-container {
          text-align: center;
        }
        .phone-container {
          p {
            text-align: center;
          }
        }
      }
    }
    .copyright {
      padding: 0.5rem 0;
    }
  }
`;

export default Footer;
