import React from "react";

//components
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import PricesTable from "./components/PricesTable";
import ConsentLink from "./components/ConsentLink";

//styles
import {
  StyledBusiness,
  StyledContainer,
  StyledInfo,
  StyledLegalization,
  StyledServices,
  StyledVacancies,
} from "../styles/jobs/employees";

//animations
import { motion } from "framer-motion";
import {
  FadeInAnimation,
  FadeLeftAnimation,
  FadeRightAnimation,
  PagesAnimation,
} from "../styles/Animations";
import { AnimateInView } from "./components/AnimateInView";

//images
import {
  electrician,
  loader,
  metal,
  metalworker,
  painter,
  plastic,
  road,
  ventilation,
  welder,
  windows,
  wood,
  worker,
} from "../img";

//files
import {
  cvPL,
  cvRU,
  legalizationPL,
  legalizationRU,
  recruitmentPL,
  recruitmentRU,
  registrationPL,
  registrationRU,
} from "../files";
//languages
import { langContent } from "../languages";

const Employees = (props) => {
  let lang = langContent;
  if (props.language === "Polish") {
    lang = langContent.Polish;
  } else if (props.language === "English") {
    lang = langContent.English;
  } else {
    lang = langContent.Russian;
  }

  const [infoElement, infoControls] = AnimateInView();

  const scrollToElement = (elementId) => {
    document.getElementById(elementId).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <motion.div
      variants={PagesAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Header language={props.language} />

      <StyledServices>
        <StyledContainer>
          <motion.div className="offer-container" variants={FadeInAnimation}>
            <h2>{lang.employees.servicesHeader}</h2>
            <ul>
              <button onClick={() => scrollToElement("vacancies")}>
                {lang.employees.servicesLi1}
              </button>
              <button onClick={() => scrollToElement("legalization")}>
                {lang.employees.servicesLi2}
              </button>
              <button onClick={() => scrollToElement("business")}>
                {lang.employees.servicesLi3}
              </button>
            </ul>
          </motion.div>
        </StyledContainer>
      </StyledServices>

      <StyledVacancies id="vacancies">
        <StyledContainer>
          <h2>{lang.employees.employmentHeader}</h2>
          <div className="guarantees">
            <h3>{lang.employees.guarantee}</h3>
            <ul>
              <li>{lang.employees.guaranteeLi1}</li>
              <li>{lang.employees.guaranteeLi2}</li>
              <li>{lang.employees.guaranteeLi3}</li>
              <li>{lang.employees.guaranteeLi4}</li>
              <li>{lang.employees.guaranteeLi5}</li>
            </ul>
          </div>
          <h3>{lang.employees.vacanciesHeader}</h3>
          <div className="vacancies-container">
            <motion.div className="row" variants={FadeInAnimation}>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={welder} alt={lang.employees.welder} />
                </div>
                <h4>{lang.employees.welder}</h4>
              </motion.div>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={metalworker} alt={lang.employees.metalworker} />
                </div>
                <h4>{lang.employees.metalworker}</h4>
              </motion.div>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={electrician} alt={lang.employees.electrician} />
                </div>
                <h4>{lang.employees.electrician}</h4>
              </motion.div>
            </motion.div>

            <motion.div className="row" variants={FadeInAnimation}>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={painter} alt={lang.employees.painter} />
                </div>
                <h4>{lang.employees.painter}</h4>
              </motion.div>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={ventilation} alt={lang.employees.ventilation} />
                </div>
                <h4>{lang.employees.ventilation}</h4>
              </motion.div>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={road} alt={lang.employees.roadworker} />
                </div>
                <h4>{lang.employees.roadworker}</h4>
              </motion.div>
            </motion.div>

            <motion.div className="row" variants={FadeInAnimation}>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={wood} alt={lang.employees.woodworker} />
                </div>
                <h4>{lang.employees.woodworker}</h4>
              </motion.div>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={loader} alt={lang.employees.forklift} />
                </div>
                <h4>
                  <pre>{lang.employees.forklift}</pre>
                </h4>
              </motion.div>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={plastic} alt={lang.employees.windowsMaker} />
                </div>
                <h4>{lang.employees.plastic}</h4>
              </motion.div>
            </motion.div>

            <motion.div className="row" variants={FadeInAnimation}>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={worker} alt={lang.employees.mattresses} />
                </div>
                <h4>
                  <pre>{lang.employees.mattresses}</pre>
                </h4>
              </motion.div>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={metal} alt={lang.employees.sheetMetal} />
                </div>
                <h4>
                  <pre>{lang.employees.sheetMetal}</pre>
                </h4>
              </motion.div>
              <motion.div className="vacancy">
                <div className="img-container">
                  <img src={windows} alt={lang.employees.windows} />
                </div>
                <h4>
                  <pre>{lang.employees.windows}</pre>
                </h4>
              </motion.div>
            </motion.div>
          </div>
        </StyledContainer>
      </StyledVacancies>

      <StyledInfo
        variants={FadeInAnimation}
        ref={infoElement}
        animate={infoControls}
      >
        <StyledContainer>
          <motion.p variants={FadeInAnimation}>
            {lang.employees.infoParagraph}
          </motion.p>
          <div className="line"></div>
          <motion.div className="info-container" variants={FadeInAnimation}>
            <motion.div className="left" variants={FadeRightAnimation}>
              <p>
                {lang.employees.infoLink}
                <a href="mailto:biuro@agwipol.pl">biuro@agwipol.pl</a>.
              </p>
              <p>{lang.employees.infoSubLink}</p>
              <ConsentLink
                docLink={
                  props.language === "Polish" ? recruitmentPL : recruitmentRU
                }
                language={props.language}
              />
            </motion.div>
            <motion.div className="right" variants={FadeLeftAnimation}>
              <a
                href={props.language === "Polish" ? cvPL : cvRU}
                target="_blank"
                rel="noreferrer"
              >
                {lang.employees.infoButton}
              </a>
            </motion.div>
          </motion.div>
        </StyledContainer>
      </StyledInfo>

      <StyledLegalization id="legalization">
        <StyledContainer>
          <h2>{lang.employees.legalizationHeader}</h2>
          <div>
            <p>{lang.employees.legalizationParagraph}</p>
            <ul>
              <li>{lang.employees.legalizationLi1}</li>
              <li>{lang.employees.legalizationLi2}</li>
              <li>{lang.employees.legalizationLi3}</li>
              <li>{lang.employees.legalizationLi4}</li>
              <li>{lang.employees.legalizationLi5}</li>
              <li>{lang.employees.legalizationLi6}</li>
              <li>{lang.employees.legalizationLi7}</li>
              <li>{lang.employees.legalizationLi8}</li>
            </ul>
            <ConsentLink
              docLink={
                props.language === "Polish" ? legalizationPL : legalizationRU
              }
              language={props.language}
              style={{ marginTop: "2rem" }}
            />
          </div>
          <PricesTable lang={lang} />
        </StyledContainer>
      </StyledLegalization>

      <StyledBusiness id="business">
        <StyledContainer>
          <h2>{lang.employees.businessHeader}</h2>
          <div>
            <p>{lang.employees.businessParagraph1}</p>
            <ul>
              <li>{lang.employees.businessList1}</li>
              <li>{lang.employees.businessList2}</li>
              <li>{lang.employees.businessList3}</li>
              <li>{lang.employees.businessList4}</li>
              <li>{lang.employees.businessList5}</li>
              <li>{lang.employees.businessList6}</li>
            </ul>
            <p>{lang.employees.businessParagraph2}</p>
            <ConsentLink
              docLink={
                props.language === "Polish" ? registrationPL : registrationRU
              }
              language={props.language}
              style={{ marginTop: "2rem" }}
            />
          </div>
        </StyledContainer>
      </StyledBusiness>

      <ScrollToTop />
    </motion.div>
  );
};

export default Employees;
