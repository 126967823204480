import React, { useEffect, useState } from "react";
//pages
import JobsHome from "./pages";
import Employers from "./pages/employers";
import Employees from "./pages/employees";
import Contact from "./pages/contact";
//components
import Nav from "./pages/components/Nav";
import Footer from "./pages/components/Footer";
import CookiesPopup from "./pages/components/CookiesPopup";
//router
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
//animations, styles and themes
import { AnimatePresence } from "framer-motion";
import styled, { ThemeProvider } from "styled-components";

function App() {
  const location = useLocation();
  const storeLanguage = (language) => {
    localStorage.setItem("storedLanguage", language);
  };
  const storedLanguage = localStorage.getItem("storedLanguage");
  const [language, setLanguage] = useState(
    storedLanguage ? storedLanguage : "Russian"
  );
  const [isPopupOpen, setPopupOpen] = useState(true);
  const theme = {
    font: language === "Russian" ? "Nunito" : "Poppins",
    "& .swal2-title": {
      color: "red",
    },
  };

  useEffect(() => {
    storedLanguage ? setLanguage(storedLanguage) : setLanguage("Russian");
  }, [storedLanguage]);

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledApp className="App">
        {isPopupOpen ? (
          <CookiesPopup
            handleClosePopup={handleClosePopup}
            language={language}
          />
        ) : null}
        <Nav
          language={language}
          languageHandler={(language) => {
            setLanguage(language);
            storeLanguage(language);
          }}
        />
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/">
              <JobsHome language={language} />
            </Route>
            <Route path="/employers">
              <Employers language={language} />
            </Route>
            <Route path="/employees">
              <Employees language={language} />
            </Route>
            <Route path="/contact">
              <Contact language={language} />
            </Route>
            <Route exact path="*">
              <Redirect to="/" />
              <JobsHome language={language} />
            </Route>
          </Switch>
        </AnimatePresence>
        <Footer language={language} />
      </StyledApp>
    </ThemeProvider>
  );
}

const StyledApp = styled.div`
  * {
    font-family: ${(props) => props.theme.font};
  }
`;

export default App;
