import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
//images
import { en, ru, pl } from "../../img";
//styles and animations
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  DropdownAnimation,
  MenuAnimation,
  BurgerMenuAnimation,
} from "../../styles/Animations";
//languages
import { langContent } from "../../languages";

const Nav = (props) => {
  const location = useLocation();
  const [langOpen, setLangOpen] = useState(false);
  const [burgerOpen, setBurgerOpen] = useState(false);

  //language text and image change
  let lang = langContent;
  let langImg;
  let langId;
  let langTxt;
  if (props.language === "Polish") {
    lang = langContent.Polish;
    langImg = pl;
    langId = "Polish";
    langTxt = lang.nav.Polish;
  } else if (props.language === "English") {
    lang = langContent.English;
    langImg = en;
    langId = "English";
    langTxt = lang.nav.English;
  } else {
    lang = langContent.Russian;
    langImg = ru;
    langId = "Russian";
    langTxt = lang.nav.Russian;
  }

  useEffect(() => {
    setBurgerOpen(false);
  }, [location]);

  useEffect(() => {
    document.body.style.overflow = burgerOpen ? "hidden" : "auto";
  }, [burgerOpen]);

  if (window.innerWidth >= 770) {
    return (
      <StyledNav>
        <ul>
          <li>
            <Link to="/">{lang.nav.home}</Link>
          </li>
          <li>
            <Link to="/employees">{lang.nav.employees}</Link>
          </li>
          <li>
            <Link to="/employers">{lang.nav.employers}</Link>
          </li>
          <li>
            <Link to="/contact">{lang.nav.contact}</Link>
          </li>
          <li
            className="dropdown lang"
            onMouseOver={() => {
              setLangOpen(true);
            }}
            onFocus={() => {
              setLangOpen(true);
            }}
            onMouseLeave={() => {
              setLangOpen(false);
            }}
            onBlur={() => {
              setLangOpen(false);
            }}
          >
            <Link
              to={location.pathname}
              id={langId}
              onClick={(event) => {
                props.languageHandler(event.target.id);
              }}
              style={{
                transform: langOpen ? "scale(1.2)" : "",
                color: langOpen ? "rgb(218, 0, 38)" : "white",
              }}
            >
              <img src={langImg} alt={langId} />
              {langTxt}
            </Link>
            <HiddenList
              variants={DropdownAnimation}
              initial="hidden"
              animate={langOpen ? "show" : "exit"}
            >
              <motion.ul variants={MenuAnimation}>
                <li>
                  <Link
                    to={location.pathname}
                    id={langId === "Polish" ? "Russian" : "Polish"}
                    onClick={(event) => {
                      props.languageHandler(event.target.id);
                      setLangOpen(false);
                    }}
                  >
                    <img src={langImg === pl ? ru : pl} alt="Polish" />
                    {langTxt === lang.nav.Polish
                      ? lang.nav.Russian
                      : lang.nav.Polish}
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to={location.pathname}
                    id={langId === "English" ? "Russian" : "English"}
                    onClick={(event) => {
                      props.languageHandler(event.target.id);
                      setLangOpen(false);
                    }}
                  >
                    <img src={langImg === en ? ru : en} alt="English" />
                    {langTxt === lang.nav.English
                      ? lang.nav.Russian
                      : lang.nav.English}
                  </Link>
                </li> */}
              </motion.ul>
            </HiddenList>
          </li>
        </ul>
      </StyledNav>
    );
  } else {
    return (
      <StyledBurgerMenu>
        <button
          className={burgerOpen ? "trigger open" : "trigger"}
          onClick={() => {
            document.body.style.overflow = burgerOpen ? "auto" : "hidden";
            setBurgerOpen(!burgerOpen);
          }}
        >
          <span className="burger"></span>
          <span className="burger-text">{lang.nav.menu}</span>
        </button>
        <motion.nav
          variants={BurgerMenuAnimation}
          animate={burgerOpen ? "show" : "exit"}
        >
          <ul>
            <li>
              <Link to="/">{lang.nav.home}</Link>
            </li>
            <li>
              <Link to="/employees">{lang.nav.employees}</Link>
            </li>
            <li>
              <Link to="/employers">{lang.nav.employers}</Link>
            </li>
            <li>
              <Link to="/contact">{lang.nav.contact}</Link>
            </li>
            <li className="burg-lang">
              <Link
                to={location.pathname}
                id={langId === "Polish" ? "Russian" : "Polish"}
                onClick={(event) => {
                  props.languageHandler(event.target.id);
                  setBurgerOpen(false);
                }}
              >
                {langTxt === lang.nav.Polish ? "RU" : "PL"}
              </Link>
              {/* <Link
                to={location.pathname}
                id={langId === "English" ? "Russian" : "English"}
                onClick={(event) => {
                  props.languageHandler(event.target.id);
                  setBurgerOpen(false);
                }}
              >
                {langTxt === lang.nav.English ? "RU" : "EN"}
              </Link> */}
            </li>
          </ul>
        </motion.nav>
      </StyledBurgerMenu>
    );
  }
};

//styles
const StyledNav = styled.nav`
  width: 1400px;
  height: 10vh;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    width: 65vw;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      text-align: center;
    }
    a {
      display: block;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 0.1em;
      &:hover {
        transform: scale(1.2);
      }
    }
    .lang {
      position: relative;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
      }
    }
  }
  * {
    transition: all 0.3s;
  }
  @media (max-width: 960px) {
    width: 95vw;
    padding: 0 2rem;
    ul {
      width: 100%;
    }
  }
`;

const HiddenList = styled(motion.div)`
  margin-top: 0.5rem;
  width: 200%;
  position: absolute;
  overflow: hidden;
  left: -50%;
  display: none;
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-top: 3px solid rgb(218, 0, 38);
    border-radius: 1rem;
    li {
      width: 100%;
      padding: 1.5rem;
      &:hover {
        background: rgba(117, 117, 117, 0.1);
        cursor: pointer;
        a {
          transform: scale(1.1);
          color: rgb(218, 0, 38);
        }
      }
    }
    a {
      color: black;
      text-align: center;
    }
  }
`;

const StyledBurgerMenu = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  .trigger {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    background: none;
    border: none;
    padding: 1rem 0 0 0;
    .burger {
      display: block;
      width: 3rem;
      height: 0.5rem;
      background: #212c36;
      transition: all 0.1s;
      &:before {
        content: "";
        display: block;
        width: 3rem;
        height: 0.5rem;
        background: #212c36;
        transition: all 0.5s;
        transform-origin: 0, 0;
        transform: translate(-0.5rem, -0.8rem) skew(15deg);
      }
      &:after {
        content: "";
        display: block;
        width: 3rem;
        height: 0.5rem;
        background: #212c36;
        transition: all 0.5s;
        transform-origin: 0, 0;
        transform: translate(-0.5rem, 0.3rem) skew(15deg);
      }
    }
    .burger-text {
      display: block;
      font-size: 1rem;
      letter-spacing: 0.05em;
      margin-top: 1rem;
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
    }
  }
  .open {
    .burger {
      background: rgb(218, 0, 38);
      background: transparent;
      &:before {
        background: rgb(218, 0, 38);
        transform: rotate(225deg);
      }
      &:after {
        background: rgb(218, 0, 38);
        transform: translateY(-0.45rem) rotate(-225deg);
      }
    }
  }
  nav {
    opacity: 0;
    visibility: hidden;
    display: block;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
    text-align: center;
    background: rgb(218, 0, 38);
    ul {
      list-style: none;
      padding: 0;
      display: inline-flex;
      flex-direction: column;
      height: 100%;
      align-items: flex-end;
      justify-content: center;
      transform: translateX(-25%) skew(-15deg);
      li {
        display: block;
        margin: 0.5rem 0;
        text-align: right;
        transform: skew(16deg);
        max-width: 180px;
        a {
          display: block;
          padding: 0.8rem 0;
          color: #212c36;
          font-size: 1.3rem;
          text-decoration: none;
          font-weight: 400;
          text-transform: uppercase;
          &:hover {
            color: rgb(218, 0, 38);
          }
        }
      }
      .burg-lang a {
        display: inline;
        color: rgb(218, 0, 38);
        &:first-of-type {
          margin-right: 1.5rem;
        }
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0rem;
      width: 100%;
      height: 100%;
      background: white;
      transform-origin: 0 0;
      transform: skew(-14deg);
    }
  }
`;

export default Nav;
