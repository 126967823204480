import React from "react";
// styles
import styled from "styled-components";

const PricesTable = ({ lang }) => {
  return (
    <StyledDiv>
      <h3 style={{ marginBottom: "1rem" }}>{lang.employees.tableHeader}</h3>
      <table>
        <tbody>
          {/* first */}
          <tr>
            <td className="itemTitle">
              <h3>{lang.employees.table.item1Left}</h3>
            </td>
            <td>
              <p>{lang.employees.table.item1Right1}</p>
              <p>{lang.employees.table.item1Right2}</p>
            </td>
          </tr>
          {/* second */}
          <tr style={{ borderTop: "1px solid black" }}>
            <td className="itemTitle">
              <h3>{lang.employees.table.item2Left}</h3>
            </td>
            <td>
              <p>{lang.employees.table.item2Right1}</p>
              <p>{lang.employees.table.item2Right2}</p>
            </td>
          </tr>
          {/* third */}
          <tr style={{ borderTop: "1px solid black" }}>
            <td className="itemTitle">
              <h3>{lang.employees.table.item3Left}</h3>
            </td>
            <td>
              <p>{lang.employees.table.item3Right1}</p>
            </td>
          </tr>
          {/* fourth */}
          <tr style={{ borderTop: "1px solid black" }}>
            <td className="itemTitle">
              <h3>{lang.employees.table.item4Left}</h3>
            </td>
            <td>
              <p>{lang.employees.table.item4Right1}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  margin-top: 2rem;
  h3 {
    color: rgb(218, 0, 38);
    font-size: 1.8rem;
  }
  table {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
    .itemTitle {
      border-right: 1px solid black;
      vertical-align: middle;
      padding: 2rem 0.5rem;
    }
    td {
      padding: 2rem 1rem;
    }
    p:last-of-type {
      margin: 0;
    }
  }
  @media all and (max-width: 768px) {
    td > h3 {
      font-size: 1.3rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

export default PricesTable;
