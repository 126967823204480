const price1 = "1400";
const price2 = "800";

export const langContent = {
  English: {
    nav: {
      English: "English",
      Russian: "Russian",
      Polish: "Polish",
      home: "Home",
      employers: "For legal entities",
      employees: "For individuals",
      contact: "Contact",
      menu: "menu",
    },

    index: {
      //offer
      offerFirst: `Services for
legal entities`,
      offerThird: `Services for
individuals`,
      formTitle: "Contact Us",
      formName: "Name:",
      formNameErrorLength1: "Please, enter your name",
      formNameErrorLength2: "Your name must be longer than 2 characters",
      formNameErrorLetters: "Your name must consist of letters only",
      formNamePlaceholder: "Enter name",
      formNumber: "Phone number:",
      formNumberErrorLength1: "Please, enter your phone number",
      formNumberErrorLength2: "Your number cannot be shorter than 7 digits",
      formNumberErrorFormat:
        "Please, enter your phone number in international format (+1)",
      formNumberPlaceholder: "Enter phone number",
      formButton: "Call back",
      formSuccessTitle: "Success!",
      formSuccessText: "We will contact you as soon as possible!",
      formErrorTitle: "Error!",
      formErrorText: "Something went wrong",
      formErrorButton: "Close",
      //services
      servicesTitle: "Our offer",
      servicesFirstHeader: "Staff recruitment",
      servicesFirstParagraph:
        "Personnel search and selection from Belarus, Ukraine, Moldova, Kazakhstan and other countries in accordance with the requirements of our customers.",
      servicesSecondHeader: "Documents",
      servicesSecondParagraph:
        "Preparing documents for the legalization of stay of our workers in Poland for the purpose of work.",
      servicesThirdHeader: "Coordination",
      servicesThirdParagraph:
        "Maintaining constant communication with employees in order to adapt them to customer requirements.",
      //principles
      principlesTitle:
        "Qualified staff is the key to the success of your business!",
      principlesParagraph:
        "The success of any company depends on the qualifications of the specialists who work there. You can count on providing the recruitment services in a short time and at a professional level while working with us. We work closely with employment agencies from Belarus, Ukraine, Moldova, Kazakhstan and other countries. Our employees are highly qualified, disciplined, efficient, honest. In addition, they quickly begin to communicate in Polish. The staff turnover is minimal. Terms of cooperation with each client are agreed individually.",
      principlesSubTitle: "Our principles",
      principlesFirstHeader: "Flexibility",
      principlesFirstParagraph:
        "We are ready to offer individual conditions of cooperation to each of our clients. Perhaps you, as an employer, would like to protect yourself from worries about organizing accommodation for employees? We will be happy to help you with this! Or maybe you, as an employee, are looking for a job in a particular city? Feel free to tell us about this, we will do our best to help you.",
      principlesSecondHeader: "Responsibility",
      principlesSecondParagraph:
        "We guarantee you informational support and assistance at any time. Planning to recruit new employees? Having difficulties in the workplace? Need help adapting on arrival? Do you need advice on preparing documents for work? We are ready to answer all your questions.",
      principlesThirdHeader: "Focus on the result",
      principlesThirdParagraph:
        "Are you ready to hire foreign workers, but do not have experience in finding candidates and preparing the necessary documents? Contact us, we will do it for you. All our candidates undergo preliminary selection, which allows us to determine the optimal employee for each client. Cooperating with us, you can be sure of the professionalism of your employees.",
    },

    employers: {
      header: `Offer for Polish
employers`,
      headerMobile: `Offer for
Polish employers`,
      //services
      servicesHeader: "Staff outsourcing",
      servicesFirstHeader: `Search and selection
of personnel`,
      servicesFirstLi1: "your staff requirements analysis",
      servicesFirstLi2: "candidate search",
      servicesFirstLi3:
        "assessment of the professional suitability of the candidate",
      servicesFirstLi4:
        "analysis of documents, confirming the qualification and experience in the specialty",
      servicesFirstLi5: "introductory interview",
      servicesFirstLi6: "testing",
      servicesFirstLi7: "assessment of personal qualities",
      servicesFirstLi8: "assessment of psychological compatibility, etc.",
      servicesFirstLi9:
        "candidate approval and coordination of the planned date of arrival at work",
      servicesSecondHeader: `Preparation of
documents`,
      servicesSecondLi1:
        "help with the paperwork required for the legal work of foreigners in Poland",
      servicesSecondLi2:
        "oświadczenie o powierzeniu wykonywania pracy cudzoziemcowi",
      servicesSecondLi3: "zezwolenie na pracę cudzoziemca",
      servicesSecondLi4: "assistance in obtaining a work visa",
      servicesSecondLi5: "formation of an agreement with an employee",
      servicesSecondLi6:
        "payment of wages, as well as payment of all taxes established by legislative acts of the Republic of Poland",
      servicesThirdHeader: `Employee
coordination`,
      servicesThirdParagraph:
        "Coordination of the employee during the entire time of his work at your enterprise:",
      servicesThirdLi1: "finding accommodation for the employee (if necessary)",
      servicesThirdLi2: "introductory safety training",
      servicesThirdLi3: "workplace adaptation assistance",
      servicesThirdLi4: "improving communication between you and the employee",
      servicesThirdLi5: "solving all arising problems and disputes",
      //graph
      graphFirst: "years of experience in staff recruitment",
      graphSecond: "people found employment",
      graphThird: "Polish empoyers work with us",
      graphFourth:
        "partners in various countries recruit work candidates for us",
      //offer
      offerParagraph:
        "We work fairly and try to do our best so that our customers can safely recommend us to their colleagues and friends. Choosing to work with us, you can be sure of the speed and quality of the services provided.",
      offerHeader: "Benefits of working with us",
      offerLi1:
        "We will develop individual cooperation conditions suitable for you",
      offerLi2: "We will help you reduce administrative costs and tax payments",
      offerLi3: "We will resolve legal and labor disputes",
      offerLi4:
        "We will reduce your costs as much as possible thanks to long-term cooperation",
      offerLi5: "We will quickly close your vacancies with optimal candidates",
      offerLi6: "We will help you reduce staff turnover",
      offerSubTitle:
        "Contact us right now - and we will find the right specialist just for you!",
    },

    employees: {
      header: `Current job offers
in Poland`,
      headerMobile: `Current job
offers in Poland`,
      //services
      servicesHeaderLeft: "We offer",
      servicesLeftLi1:
        "Timely job offers in accordance with your wishes, available skills, work experience and skill level.",
      servicesLeftLi2: "Support and consultation during your stay in Poland.",
      servicesLeftLi3:
        "Legal assistance to individuals to legalize their stay in the Republic of Poland and to resolve various disputes: consult and assist in preparing any official documents to the state bodies of Poland (legalization, obtaining a residence permit - pobyt czasowy, pobyt stały - for various reasons, citizenship of the Republic of Poland); write letters on administrative cases, appeal against an administrative decision, handling cases in courts.",
      servicesHeaderRight: "We guarantee",
      servicesRightLi1:
        "Information about the process of considering your candidacy.",
      servicesRightLi2:
        "Help with all the necessary documents for a work visa after the approval of working and living conditions, payment, date of arrival.",
      servicesRightLi3:
        "Timely payment of wages, as well as payment of all taxes established by legislative acts of the Republic of Poland.",
      servicesRightLi4:
        "Assistance in the obtaining of sanitary books, the purchase of work clothes in accordance with labor protection requirements.",
      //vacancies
      vacanciesHeader: "Our permanent vacancies",
      welder: "Welder",
      metalworker: "Metalworker",
      stonemason: "Stonemason",
      roofer: "Roofer",
      forklift: "Forklift driver",
      painter: "Metal painter",
      plastic: "Factory worker",
      carpenter: "Carpenter",
      ventilation: "Ventilation installer",
      electrician: "Electrician",
      mattresses: `Production line worker:
mattresses`,
      sheetMetal: `Production line worker:
sheet metal`,
      windows: `Production line worker:
windows`,
      roadworker: "Roadworker assistant",
      woodworker: "Woodworker assistant",
      //info
      infoParagraph:
        "This list of vacancies is not exhaustive. We monitor the market, enter into new partnership agreements with Polish employers, and are constantly developing. Thanks to all that we can satisfy almost every job search request.",
      infoLink:
        "If you are interested in our job offers, download and fill out the application form, then send it to us to: ",
      infoSubLink: "Our specialists will contact you.",
      infoButton: "Download CV",
    },

    contact: {
      header: `Contact`,
      //form
      formName: "Name*",
      formCompany: "Company",
      formEmail: "Email*",
      formSubject: "Subject*",
      formMessage: "Message*",
      formMessagePlaceholder: "Enter your message...",
      formButton: "Send",
      formNameError1: "Please, enter your name",
      formNameError2: "Your name must contain at least 2 characters",
      formNameError3: "Your name must consist of letters only",
      formEmailError1: "Please, enter your e-mail address",
      formEmailError2: "Please, enter a valid e-mail address",
      formSubjectError1: "Please, enter your subject",
      formSubjectError2: "Your subject must contain at least 2 characters",
      formMessageError1: "Please, write your message",
      formMessageError2: "Your message must contain at least 2 characters",
      //right column
      email: "Email",
      phones: "Phone numbers",
      address: "Address",
    },

    footer: {
      linksTitle: "Quick Links",
      infoTitle: "Information",
      contactsTitle: "Contacts",
    },
  },

  Russian: {
    nav: {
      English: "English",
      Russian: "Русский",
      Polish: "Polski",
      home: "Главная",
      employers: "Для юридических лиц",
      employees: "Для физических лиц",
      contact: "Контакты",
      menu: "меню",
      privacyPolicy: "Политика конфиденциальности",
    },

    index: {
      //offer
      offerFirst: "Услуги для физических лиц",
      subOfferFirst:
        "помощь в трудоустройстве в Польше, оформлении карты побыта, гражданства, PESEL, открытии фирмы/ИП, замене водительских прав, постановке на учет авто и т.д.",
      offerThird: "Услуги для юридических лиц",
      subOfferThird:
        "аутсорсинг персонала, помощь в легализации работников, оформление разрешений на работу по доверенности",
      formTitle: `Свяжитесь с нами`,
      formName: "Имя:",
      formNameErrorLength1: "Пожалуйста, введите имя",
      formNameErrorLength2: "Ваше имя не может быть короче 2 символов",
      formNameErrorLetters: "Ваше имя должно состоять только из букв",
      formNamePlaceholder: "Введите имя",
      formNumber: "Контактный номер Viber/Telegram:",
      formNumberErrorLength1: "Пожалуйста, введите номер телефона",
      formNumberErrorLength2: "Ваш номер телефона не может быть короче 7 цифр",
      formNumberErrorFormat:
        "Пожалуйста, введите номер с кодом страны (например, +375)",
      formNumberPlaceholder: "Введите номер телефона",
      formButton: "Перезвоните мне",
      formSuccessTitle: "Отправлено!",
      formSuccessText: "Мы скоро свяжемся с вами!",
      formErrorTitle: "Ошибка!",
      formErrorText: "Что-то пошло не так",
      formErrorButton: "Закрыть",
      formConsentData:
        "Я даю согласие на обработку компанией AgwiPol моих персональных данных для контакта со мной на указанный мной электронный адрес или на указанный мной номер телефона.*",
      formConsentDoc:
        "Я заявляю, что ознакомился(-ась) с информацией об обработке персональных и понимаю её содержание.*",
      formConsentLink:
        "С информацией об обработке персональных данных можно ознакомиться ",
      formConsentLinkText: "здесь",

      //services
      servicesTitle: "Мы предлагаем",
      servicesFirstHeader: "Помощь в трудоустройстве в Польше",
      servicesFirstParagraph:
        "Только проверенные вакансии. Оформление документов для легализации пребывания на территории Польши с целью работы. Помощь в адаптации, решение всех возникающих проблем. Трудоустройство осуществляется бесплатно.",
      servicesSecondHeader: "Услуги по легализации в Польше",
      servicesSecondParagraph:
        "Помощь в оформлении карты побыта (ВНЖ) в Польше, получении гражданства, PESEL, мельдунка (регистрации по месту жительства), постановке на учет авто, составлении апелляций в различные инстанции, оформление заявлений на материальную помощь Rodzina 500+.",
      servicesThirdHeader: "Открытие и сопровождение бизнеса",
      servicesThirdParagraph:
        "Регистрация ИП/ООО, открытие фирменных счетов, внесение изменений в регистры, проверка контрагентов, услуги виртуального офиса, ликвидация фирм.",

      //principles
      principlesTitle:
        "Квалифицированный персонал – ключ к успеху вашего бизнеса!",
      principlesParagraph:
        "Успех любой компании зависит от квалификации специалистов, которые в ней работают. Обратившись к нам, вы можете рассчитывать на предоставление услуг по подбору персонала в короткие сроки и на профессиональном уровне. Мы тесно сотрудничаем с агентствами по трудоустройству из Беларуси, Украины, Молдовы, Казахстана и других стран. Наши сотрудники являются высококвалифицированными, дисциплинированными, эффективными, честными и быстро обучаемыми. Кроме того, они в короткие сроки начинают общаться на польском языке. Текучесть кадров минимальна. Условия сотрудничества с каждым клиентом согласовываются индивидуально.",
      principlesSubTitle: "Наши принципы",
      principlesFirstHeader: "Гибкость",
      principlesFirstParagraph:
        "Каждому нашему клиенту мы готовы предложить индивидуальные условия сотрудничества. Возможно, Вы, как работодатель, хотели бы оградить себя от забот по организации проживания для работников? Мы с радостью поможем Вам в этом! Или может быть Вы, как работник, ищете вакансию в каком-то определенном городе? Не стесняйтесь сообщить нам об этом, мы сделаем все, чтобы Вам помочь.",
      principlesSecondHeader: "Ответственность",
      principlesSecondParagraph:
        "Мы гарантируем Вам информационную поддержку и помощь в любое время. Планируете набрать новых работников? Возникли трудности на рабочем месте? Необходима помощь в адаптации по приезду? Вам нужна консультация по вопросам оформления документов на работу к нам? Мы готовы ответить на все ваши вопросы.",
      principlesThirdHeader: "Нацеленность на результат",
      principlesThirdParagraph:
        "Вы готовы принимать на работу иностранных работников, однако не имеете опыта в поиске кандидатов и оформлении необходимых документов? Обращайтесь, мы сделаем это за вас. Все наши кандидаты проходят предварительный отбор, позволяющий определить оптимального сотрудника для каждого клиента. Сотрудничая с нами, вы можете быть уверены в профессионализме своих работников.",
    },

    employers: {
      header: `Услуги для
юридических лиц`,
      //services
      servicesHeader: "Аутсорсинг персонала",
      servicesFirstHeader: `Поиск и отбор
персонала`,
      servicesFirstLi1: "анализ Ваших требований к кандидату",
      servicesFirstLi2: "поиск кандидатов",
      servicesFirstLi3: "оценка профессиональной пригодности кандидата",
      servicesFirstLi4:
        "анализ документов, подтверждающих квалификацию и опыт работы по специальности",
      servicesFirstLi5: "вступительное собеседование",
      servicesFirstLi6: "тестирование",
      servicesFirstLi7: "оценка личностных качеств",
      servicesFirstLi8: "оценка психологической совместимости и т.д.",
      servicesFirstLi9:
        "утверждение кандидатуры и согласование планируемой даты приезда на работу",
      servicesSecondHeader: `Оформление
документов`,
      servicesSecondLi1:
        "оформление документов, необходимых для легальной работы иностранцев в Польше",
      servicesSecondLi2:
        "oświadczenie o powierzeniu wykonywania pracy cudzoziemcowi",
      servicesSecondLi3: "zezwolenie na pracę cudzoziemca",
      servicesSecondLi4: "оказание содействия в получении рабочей визы",
      servicesSecondLi5: "заключение договора с работником",
      servicesSecondLi6:
        "выплата заработной платы, а также оплата всех налогов, установленных законодательными актами Республики Польша",
      servicesThirdHeader: `Сопровождение
работника`,
      servicesThirdParagraph:
        "Координация работника в течение всего времени выполнения работы на Вашем предприятии:",
      servicesThirdLi1:
        "организация проживания для работника (при необходимости)",
      servicesThirdLi2:
        "проведение вступительного инструктажа по технике безопасности",
      servicesThirdLi3: "помощь в адаптации на рабочем месте",
      servicesThirdLi4: "улучшение коммуникации между Вами и работником",
      servicesThirdLi5: "решение всех возникающих проблем и споров",
      //graph
      graphFirst: "года опыта в сфере подбора персонала",
      graphSecond: "работников трудоустроенных через нашу компанию",
      graphThird: "польских работодателя сотрудничают с нами",
      graphFourth: "партнеров в разных странах рекрутируют для нас кандидатов",

      // offer
      offerParagraph1:
        "Вам нужна помощь в оформлении разрешений на работу для своих работников? Мы готовы сделать это для Вас по доверенности. Также мы готовы оказывать Вашим работникам и их семьям помощь в легализации на территории Польши: оформлять карты побыта, гражданство, перерегистрировать авто, менять права, подавать семьи на 500+ и многое другое.",
      offerParagraph2:
        "Мы работаем на совесть и стараемся сделать все возможное, чтобы наши клиенты могли смело рекомендовать нас своим коллегам и друзьям. Обращаясь к нам, вы можете быть уверены в скорости и качестве оказываемых услуг.",
      offerHeader: "Преимущества работы с нами",
      offerLi1:
        "Разработаем индивидуальные условия сотрудничества, подходящие именно вам",
      offerLi2:
        "Поможем вам сократить административные расходы и налоговые выплаты",
      offerLi3: "Возьмем на себя разрешение правовых и трудовых споров",
      offerLi4:
        "Максимально сократим ваши затраты, благодаря долгосрочному сотрудничеству",
      offerLi5: "Быстро закроем ваши вакансии оптимальными кандидатами",
      offerLi6: "Поможем снизить текучесть кадров",
      offerSubTitle:
        "Обратитесь к нам прямо сейчас – и мы найдем для вас нужного специалиста!",
    },

    employees: {
      header: `Услуги для
физических лиц`,
      //services
      servicesHeader: "Мы предлагаем",
      servicesLi1: "Помощь в трудоустройстве",
      servicesLi2: "Помощь в легализации",
      servicesLi3: "Помощь в открытии бизнеса",

      //employment
      employmentHeader: "Трудоустройство",
      guarantee: "Мы гарантируем",
      guaranteeLi1: "Информирование о статусе рассмотрения Вашей кандидатуры.",
      guaranteeLi2:
        "Оформление документов, необходимых для получения польской рабочей визы (после согласования условий работы и проживания, оплаты труда, даты приезда).",
      guaranteeLi3:
        "Своевременную выплату заработной платы, а также оплату всех налогов, установленных законодательными актами Республики Польша.",
      guaranteeLi4:
        "Помощь в оформлении санитарных книжек, приобретении рабочей одежды в соответствии с требованиями по охране труда.",
      guaranteeLi5:
        "Актуальные вакансии в Польше, которые соответствуют Вашим пожеланиям, опыту работы и квалификации.",

      //vacancies
      vacanciesHeader: "Наши постоянные вакансии",
      welder: "Сварщик",
      metalworker: "Слесарь",
      stonemason: "Каменщик",
      roofer: "Кровельщик",
      forklift: `Работник склада
(водитель погрузчика)`,
      painter: "Покрасчик металлоконструкций",
      plastic: `Разнорабочий на производство
пластика`,
      carpenter: "Столяр",
      ventilation: "Монтёр вентиляционных исталляций",
      electrician: "Электрик",
      mattresses: `Производство матрасов
типа футон`,
      sheetMetal: `Производство элементов
из листового металла`,
      windows: `Разнорабочий на
производство окон`,
      roadworker: `Разнорабочий на
дорожные работы`,
      woodworker: `Разнорабочий на
деревообработку`,

      //info
      infoParagraph:
        "Данный список вакансий не является исчерпывающим. Мы следим за рынком, заключаем партнерские соглашения с польскими работодателями, постоянно развиваемся, благодаря чему можем удовлетворить практически каждый запрос на поиск работы.",
      infoLink:
        "Если наши вакансии Вас заинтересовали, заполните анкету, затем отправьте её нам на электронную почту: ",
      infoSubLink: "Наши специалисты обязательно с Вами свяжутся!",
      infoButton: "Скачать CV",

      // legalization
      legalizationHeader: "Легализация",
      legalizationParagraph:
        "Мы оказываем консультации и осуществляем практическую помощь в оформлении любых официальных документов в государственных органах Польши, по всем основаниям, на любом этапе рассмотрения дела:",
      legalizationLi1:
        "получение PESEL, профиля зауфанего (электронная цифровая подпись), мельдунка (регистрации по месту жительства), открытие банковского счета",
      legalizationLi2:
        "получение карты часовего побыта (временный вид на жительство), карты сталего побыта (постоянный вид на жительство) - по разным основаниям, карты резидента ЕС (разрешение на пребывание долгосрочного резидента ЕС)",
      legalizationLi3: "получение гражданства Республики Польша",
      legalizationLi4:
        "составление писем по административным делам и обжалование административного решения",
      legalizationLi5: "регистрация автомобиля",
      legalizationLi6: "замена водительского удостоверения",
      legalizationLi7: "оформление Rodzina 500+",
      legalizationLi8: "и другие",

      // prices table
      tableHeader: "Стоимость услуг",
      table: {
        item1Left: "Помощь в оформлении карты побыта (ВНЖ)",
        item1Right1: `Базовый пакет (консультация, подготовка пакета документов, заполнение анкет) - ${price2} зл.`,
        item1Right2: `Расширенный пакет (консультация, подготовка пакета документов, заполнение анкет, сопровождение при подаче и сдаче отпечатков пальцев, получение корреспонденции из уженда, получение решения) - ${price1} зл.`,
        item2Left: "Помощь в получении польского гражданства",
        item2Right1: `Базовый пакет (консультация, подготовка пакета документов, заполнение анкет) - ${price2} зл.`,
        item2Right2: `Расширенный пакет (консультация, заполнение анкет, транскрипция (регистрация) заграничных свидетельства о рождении, браке в польском реестре записи актов гражданского состояния (ЗАГС) (umiejscowienie), присяжные переводы, сопровождение при подаче, изготовление всех копий, предоставление нашего адреса для корреспонденции, высылка всех дополнительных документов при необходимости) - ${price1} зл.`,
        item3Left: "Получение песель, профиля зауфаного, мельдунка",
        item3Right1: "100 зл. Срок реализации - 1 день",
        item4Left: "Прочие услуги",
        item4Right1: "По согласованию",
      },

      // help with business
      businessHeader: "Открытие бизнеса",
      businessParagraph1:
        "Оказываем правовую помощь лицам, желающим открыть или вести в Республике Польша свой бизнес",
      businessParagraph2: "Стоимость услуг - по согласованию сторон",
      businessList1: "регистрация фирм любой формы собственности",
      businessList2:
        "сопровождение при открытии счета в банке, а также при получении кредитов в Польше для юридического лица",
      businessList3: "внесение изменений в учетные регистры и налоговые учеты",
      businessList4:
        "проведение мероприятий по проверке контрагентов в Республике Польша",
      businessList5:
        "виртуальный офис (предоставление юр. адреса для регистрации компании, адреса для корреспонденции, сканирование всей приходящей документации, возможность отправки корреспонденции почтой или курьером)",
      businessList6:
        "урегулирование любых юридических споров, ликвидация хозяйственной деятельности и продажа готовых фирм",
    },

    contact: {
      header: `Контакты`,
      //form
      formName: "Имя*",
      formCompany: "Название фирмы",
      formEmail: "Email*",
      formSubject: "Тема*",
      formMessage: "Сообщение*",
      formMessagePlaceholder: "Введите сообщение...",
      formButton: "Отправить",
      formNameError1: "Пожалуйста, введите ваше имя",
      formNameError2: "Ваше имя не может быть короче 2 символов",
      formNameError3: "Ваше имя должно состоять только из букв",
      formEmailError1: "Пожалуйста, введите ваш адрес email",
      formEmailError2: "Пожалуйста, введите действительный адрес email",
      formSubjectError1: "Пожалуйста, укажите тему",
      formSubjectError2: "Тема сообщения не может быть короче 2 символов",
      formMessageError1: "Пожалуйста, напишите сообщение",
      formMessageError2: "Ваше сообщение должно содержать минимум 2 символа",
      //right column
      email: "Email",
      phones: "Контактные номера",
      address: "Адрес",
    },

    footer: {
      linksTitle: "Быстрые ссылки",
      infoTitle: "Информация",
      contactsTitle: "Контакты",
    },

    cookies: {
      header: "Политика файлов cookie",
      paragraph:
        'Сайт использует файлы cookie, благодаря которым он может работать лучше. Это упрощает взаимодействие с сайтом и делает его полезнее для вас, а также позволяет нам формировать статистику и улучшать функциональность сайта. Просим вас сделать правильный выбор относительно ваших предпочтений в отношении функционирования файлов cookie. Нажав "отклонить cookies", вы автоматически откажетесь от использования всех технологий, кроме технически необходимых. Нажав "настроить разрешения", вы можете на следующем шаге индивидуально настроить свои предпочтения в отношении дополнительных файлов cookie. Нажимая "я принимаю cookies", вы соглашаетесь на использование технологии cookies в соответствии с настройками вашего браузера.',
      acceptBtn: "Принять cookies",
      rejectBtn: "Отклонить cookies (кроме необходимых)",
      detailsBtn: "Настроить разрешения",
      functionalCheckbox: "Принять функциональные cookies",
      analyticalCheckbox: "Принять аналитические cookies",
      marketingCheckbox: "Принять рекламные cookies",
      saveBtn: "Сохранить настройки",
    },

    consent: {
      consentLink:
        "С информацией об обработке персональных данных можно ознакомиться ",
      consentLinkText: "здесь",
    },
  },

  Polish: {
    nav: {
      English: "English",
      Russian: "Русский",
      Polish: "Polski",
      home: "Strona główna",
      employers: "Dla osób prawnych",
      employees: "Dla osób fizycznych",
      contact: "Kontakt",
      menu: "menu",
      privacyPolicy: "Polityka prywatności",
    },

    index: {
      offerFirst: `Usługi dla
osób fizycznych`,
      subOfferFirst:
        "pomoc w znalezieniu pracy w Polsce, uzyskaniu karty pobytu, obywatelstwa, numeru PESEL, założeniu firmy/JDG, wymianie zagranicznego prawa jazdy, rejestracji samochodu itp.",
      offerThird: `Usługi dla
osób prawnych`,
      subOfferThird:
        "outsourcing personelu, pomoc w legalizacji pracowników, ubieganie się o zezwolenia na pracę, oświadczenia przez pełnomocnictwo",
      formTitle: `Skontaktuj się
z nami`,
      formName: "Imię:",
      formNameErrorLength1: "Proszę podać swoje imię",
      formNameErrorLength2: "Imię nie może być krótsze niż 2 litery",
      formNameErrorLetters: "Imię musi składać się wyłącznie z liter",
      formNamePlaceholder: "Wpisz swoję imię",
      formNumber: "Numer telefonu:",
      formNumberErrorLength1: "Proszę podać swój numer telefonu",
      formNumberErrorLength2: "Numer telefonu nie może być krótszy niż 7 cyfr",
      formNumberErrorFormat:
        "Proszę wpisać numer telefonu w formacie międzynarodowym (+48)",
      formNumberPlaceholder: "Wpisz swój numer",
      formButton: "Zadzwoń do mnie",
      formSuccessTitle: "Sukces!",
      formSuccessText: "Skontaktujemy się z tobą w najkrutszym okresie!",
      formErrorTitle: "Błąd!",
      formErrorText: "Coś poszło nie tak",
      formErrorButton: "Zamknij",
      formConsentData:
        "Wyrażam zgodę na przetwarzanie przez AgwiPol Sp. z o.o. moich danych osobowych w celu umożliwienia kontaktu drogą elektroniczną poprzez formularz na stronie internetowej bądź oddzwonienia na podany numer telefonu.*",
      formConsentDoc:
        "Oświadczam, że zapoznałam/em się z treścią informacji o przetwarzaniu danych osobowych i rozumiem jej treść.*",
      formConsentLink: "Informacja o przetwarzaniu danych osobowych dostępna ",
      formConsentLinkText: "TUTAJ",

      //services
      servicesTitle: "Nasza oferta",
      servicesFirstHeader: "Outsourcing personelu",
      servicesFirstParagraph:
        "Tylko zweryfikowane oferty pracy. Legalizacja pobytu w Polsce w celu wykonywania pracy. Koordynacja pracy, pomoc w adaptacji, rozwiązanie wszelkich pojawiających się problemów",
      servicesSecondHeader: "Pomoc w legalizacji w Polsce",
      servicesSecondParagraph:
        "Pomoc w uzyskaniu karty pobytu (zezwolenia na pobyt) w Polsce, uznaniu za obywatela polskiego, nadaniu obywatelstwa polskiego, uzyskaniu numeru PESEL, meldunku, wymianie prawa jazdy na polskie, sporządzeniu odwołań do różnych urzędów, wypełnienie wniosków Rodzina 500+.",
      servicesThirdHeader: "Rejestracja i wsparcie Biznesu",
      servicesThirdParagraph:
        "Rejestracja jednoosobowej działalności gospodarczej/Sp. z o.o, założenie rachunków firmowych, dokonywanie zmian w rejestrach, weryfikacja kontrahentów, usługi wirtualnego biura, likwidacja firm.",

      //principles
      principlesTitle:
        "Wykwalifikowany personel jest kluczem do sukcesu w biznesie!",
      principlesParagraph:
        "Sukces każdej firmy zależy od kwalifikacji specjalistów, którzy w niej pracują. Zwracając się do nas, możesz liczyć na świadczenie usług rekrutacyjnych w krótkim czasie i na profesjonalnym poziomie. Ściśle współpracujemy z agencjami pośrednictwa pracy z Białorusi, Ukrainy, Mołdawii, Kazachstanu i innych krajów. Nasi pracownicy są wysoko wykwalifikowani, zdyscyplinowani, wydajni, uczciwi i potrafią się uczyć. Oprócz tego szybko zaczynają komunikować się w języku polskim. Rotacja personelu jest minimalna. Warunki realizacji prac są każdorazowo koordynowane indywidualnie.",
      principlesSubTitle: "Nasze zasady pracy",
      principlesFirstHeader: "Elastyczność",
      principlesFirstParagraph:
        "Jesteśmy gotowi zaoferować każdemu z naszych klientów indywidualne warunki współpracy. Być może jako pracodawca chciałbyś uchronić się przed obawami związanymi z organizacją zakwaterowania dla pracowników? Z przyjemnością Ci w tym pomożemy! A może ty jako pracownik szukasz wakatu w jakimś konkretnym mieście? Daj nam znać o tym, dołożymy wszelkich starań, aby Ci pomóc.",
      principlesSecondHeader: "Odpowiedzialność",
      principlesSecondParagraph:
        "Gwarantujemy wsparcie informacyjne i pomoc w dowolnym momencie. Planujesz rekrutować nowych pracowników? Masz trudności w miejscu pracy? Potrzebujesz pomocy w dostosowaniu w dniu przyjazdu? Potrzebujesz porady na temat dokumentacji związanej z pracą? Jesteśmy gotowi odpowiedzieć na wszystkie pytania.",
      principlesThirdHeader: "Skupienie się na wyniku",
      principlesThirdParagraph:
        "Współpracując z nami możesz być pewien profesjonalizmu swoich pracowników. Jesteś gotowy zatrudnić zagranicznych pracowników, ale nie masz doświadczenia w poszukiwaniu kandydatów i przygotowywaniu niezbędnych dokumentów? Skontaktuj się z nami, zrobimy to za Ciebie. Wszyscy nasi kandydaci przechodzą wstępną selekcję, która pozwala nam wybrać optymalnego pracownika dla każdego klienta.",
    },

    employers: {
      header: `Usługi dla
osób prawnych`,
      //services
      servicesHeader: "Outsourcing pracowników",
      servicesFirstHeader: `Rekrutacja
pracowników`,
      servicesFirstLi1:
        "analiza stawianych przez Państwa firmę wymagań do kandydatów",
      servicesFirstLi2: "wyszukiwanie kandydatów",
      servicesFirstLi3: "ocena sprawności zawodowej kandydata",
      servicesFirstLi4:
        "analiza dokumentów potwierdzających kwalifikacje i doświadczenie zawodowe",
      servicesFirstLi5: "rozmowa kwalifikacyjna",
      servicesFirstLi6: "testowanie",
      servicesFirstLi7: "sprawdzanie cech osobistych",
      servicesFirstLi8: "sprawdzanie zgodności psychologicznej i t.p.",
      servicesFirstLi9:
        "zatwierdzenie kandydatury i uzgodnienie planowanej daty przyjazdu do pracy",
      servicesSecondHeader: `Przygotowanie
dokumentów`,
      servicesSecondLi1:
        "uzyskanie dokumentów niezbędnych do podjęcia legalnej pracy przez cudzoziemców w Polsce",
      servicesSecondLi2:
        "oświadczenie o powierzeniu wykonywania pracy cudzoziemcowi",
      servicesSecondLi3: "zezwolenie na pracę cudzoziemca",
      servicesSecondLi4:
        "pomoc w złożeniu dokumentów w celu uzyskania wizy pracowniczej",
      servicesSecondLi5: "zawarcie umowy z pracownikiem",
      servicesSecondLi6:
        "wypłata wynagrodzeń oraz płatność wszystkich podatków określonych przez akty prawne Rzeczypospolitej Polskiej",
      servicesThirdHeader: `Stosunki
pracy`,
      servicesThirdParagraph:
        "Koordynacja pracownika przez cały czas pracy na Państwa zakładzie:",
      servicesThirdLi1:
        "organizacja zakwaterowania dla pracownika (w razie potrzeby)",
      servicesThirdLi2: "przeprowadzenie wstępnego szkolenia BHP",
      servicesThirdLi3: "pomoc w adaptacji w miejscu pracy",
      servicesThirdLi4: "pomoc w komunikacji między Państwem a pracownikiem",
      servicesThirdLi5:
        "rozwiązywanie wszystkich pojawiających się problemów i sporów",

      //graph
      graphFirst: "lat doświadczenia w zakresie rekrutacji pracowników",
      graphSecond: "pracowników zatrudnionych przez naszą firmę",
      graphThird: "pracodawcy z Polski współpracują z nami",
      graphFourth: "firm partnerskich za granicą rekrutują dla nas kandydatów",

      // offer
      offerParagraph1:
        "Potrzebujesz pomocy w uzyskaniu zezwolenia na pracę lub oświadczenia dla swoich pracowników? Jesteśmy gotowi zrobić to za Ciebie. Jesteśmy również gotowi pomóc Twoim pracownikom i ich rodzinom w legalizacji w Polsce: skonsultować w sprawie uzyskania karty pobytu, obywatelstwa, zarejestrować samochód, wymienić zagraniczne prawa jazdy na polskie, wypełnić wniosek o Rodzina 500+ i wiele więcej.",
      offerParagraph2:
        "Robimy wszystko, co możliwe, aby upewnić się, że nasi klienci są usatysfakcjonowani i polecają nas swoim przyjaciołom i znajomym. Pracując na tym rynku, działamy z myślą o ludziach, dla których i z którymi pracujemy, o ich potrzebach biznesowych.",
      offerHeader: "Jakie koryści dla państwa?",
      offerLi1:
        "Warunki realizacji prac są każdorazowo koordynowane indywidualnie",
      offerLi2: "Zmniejszenie kosztów administracyjnych i obciążeń podatkowych",
      offerLi3: "Eliminowanie sporów prawnych i pracowniczych",
      offerLi4: "Dzięki stałej współpracy koszty są zmniejszane",
      offerLi5: "Wakaty są zamykane szybko i precyzyjnie",
      offerLi6: "Rotacja personelu jest minimalna",
      offerSubTitle:
        "Zwracając się do nas, możecie liczyć na świadczenie usług rekrutacyjnych w krótkim czasie i na profesjonalnym poziomie!",
    },

    employees: {
      header: `Usługi dla
osób fizycznych`,
      //services
      servicesHeader: "Oferuemy",
      servicesLi1: "Pomoc w znalezieniu pracy",
      servicesLi2: "Pomoc w legalizacji",
      servicesLi3: "Pomoc w rejestracji firm",

      //employment
      employmentHeader: "Pomóc w znalezeniu pracy",
      guarantee: "Gwarantujemy",
      guaranteeLi1: "Informowanie o stanie rozpatrzenia Twojej kandydatury.",
      guaranteeLi2:
        "Dokumenty wymagane do uzyskania polskiej wizy pracowniczej (po uzgodnieniu warunków pracy i życia, wynagrodzenia, daty przyjazdu).",
      guaranteeLi3:
        "Terminowa wypłata wynagrodzeń, a także zapłata wszystkich podatków ustanowionych aktami ustawodawczymi Rzeczypospolitej Polskiej.",
      guaranteeLi4:
        "Pomoc w uzyskaniu książek sanitarnych, zakupach odzieży roboczej zgodnie z wymogami ochrony pracy.",
      guaranteeLi5:
        "Aktualne oferty pracy w Polsce, które odpowiadają Twoim życzeniom, doświadczeniu zawodowemu i kwalifikacjom.",

      //vacancies
      vacanciesHeader: "Nasze oferty pracy",
      welder: "Spawacz",
      metalworker: "Ślusarz",
      stonemason: "Murarz",
      roofer: "Dacharz",
      forklift: `Pracownik na magazynie`,
      painter: "Malarz",
      plastic: `Pracownik zakładu`,
      carpenter: "Stolarz",
      ventilation: "Monter instalacji wentylacyjnych",
      electrician: "Elektryk",
      mattresses: `Pracownik na linii
produkcyjnej`,
      sheetMetal: `Fachowiec`,
      windows: `Pracownik fizyczny`,
      roadworker: "Robotnik do prac drogowych",
      woodworker: "Pracownik tartaku",

      //info
      infoParagraph:
        "Ta lista ofert pracy nie jest wyczerpująca. Monitorujemy rynek, zawieramy umowy partnerskie z polskimi pracodawcami i stale się rozwijamy, dzięki czemu możemy zaspokoić prawie każde żądanie na rynku pracy.",
      infoLink: "Pobierz i wypełnij poniższy formularz i wyślij go do nas na ",
      infoSubLink:
        "Nasz specjalista skontaktuje się z Tobą dla dalszych działań.",
      infoButton: "Pobierz formę CV",

      // legalization
      legalizationHeader: "Legalizacja",
      legalizationParagraph:
        "Służymy radą i praktyczną pomocą na każdym etapie sprawy podczas ubiegania się o dokumenty urzędowe w organach państwowych RP:",
      legalizationLi1:
        "uzyskanie numeru PESEL, Profilu Zaufanego, meldunku, otwarcie rachunku bankowego",
      legalizationLi2:
        "uzyskanie karty czasowego pobytu (zezwolenia na pobyt czasowy), karty stałego pobytu (zezwolenia na pobyt stały) - z różnych powodów, zezwolenia na pobyt rezydenta długoterminowego UE",
      legalizationLi3:
        "uznanie za obywatela polskiego, nadanie obywatelstwa polskiego",
      legalizationLi4:
        "sporządzanie pism w sprawach administracyjnych oraz odwołań od decyzji administracyjnych",
      legalizationLi5: "rejestracja samochodu",
      legalizationLi6: "wymiana zagranicznego prawa jazdy na polskie",
      legalizationLi7: "wypełnianie wniosków Rodzina 500+",
      legalizationLi8: "itd.",

      // prices table
      tableHeader: "Cennik usług",
      table: {
        item1Left: "Pomoc w uzyskaniu karty czasowego pobytu",
        item1Right1: `Pakiet podstawowy (konsultacja, przygotowanie pakietu dokumentów, wypełnienie wniosków) - ${price2} zł.`,
        item1Right2: `Pakiet rozszerzony (konsultacja, przygotowanie pakietu dokumentów, wypełnienie wniosków, towarzyszenie podczas składania dokumentów i odcisków linii papilarnych, odbiór korespondencji z urzędu, odbiór decyzji) - ${price1} zł.`,
        item2Left:
          "Pomoc w uznaniu za obywatela polskiego, nadaniu obywatelstwa polskiego",
        item2Right1: `Pakiet podstawowy (konsultacja, przygotowanie pakietu dokumentów, wypełnienie wniosków) - ${price2} zł.`,
        item2Right2: `Pakiet rozszerzony (konsultacje, wypełnienie wniosków, umiejscowienie zagranicznych aktów urodzenia i ślubu, tłumaczenia przysięgłe, towarzyszenie podczas składania dokumentów i odcisków linii papilarnych, sporządzenie wszelkich kopii, podanie naszego adresu do korespondencji, wysyłanie wszystkich dodatkowych dokumentów w razie potrzeby) - ${price1} zł.`,
        item3Left: "Pesel, profil zaufany, meldunek",
        item3Right1: "100 zł. Termin realizacji - 1 dzień",
        item4Left: "Inne usługi",
        item4Right1: "Ustala się indywidualnie",
      },

      // help with business
      businessHeader: "Rejestracja firmy",
      businessParagraph1:
        "Służymy pomocą osobom pragnącym prowadzić własną działalność gospodarczą na terenie Rzeczypospolitej Polskiej",
      businessParagraph2: "Koszt usług - zgodnie z ustaleniami stron",
      businessList1: "rejestracja firm o dowolnej formie własności",
      businessList2:
        "wsparcie przy założeniu rachunku bankowego, a także przy uzyskiwaniu kredytów i pożyczek w Polsce dla osoby prawnej",
      businessList3: "wprowadzanie zmian w rejestrach",
      businessList4: "weryfikacja kontrahentów na terenie RP",
      businessList5:
        "wirtualne biuro (podanie adresu prawnego do rejestracji firmy, adresu do korespondencji, skanowanie całej przychodzącej dokumentacji, możliwość wysłania korespondencji pocztą lub kurierem)",
      businessList6:
        "rozstrzyganie wszelkich sporów prawnych, likwidacja działalności gospodarczej i sprzedaż gotowych spółek",
    },

    contact: {
      header: `Kontakt`,
      //form
      formName: "Imię*",
      formCompany: "Firma",
      formEmail: "Email*",
      formSubject: "Temat*",
      formMessage: "Wiadomość*",
      formMessagePlaceholder: "Wpisz swoją wiadomość tu...",
      formButton: "Wysłać",
      formNameError1: "Proszę podać swoje imię",
      formNameError2: "Imię nie może być krótsze niż 2 litery",
      formNameError3: "Imię musi składać się wyłącznie z liter",
      formEmailError1: "Proszę podać swój adres email",
      formEmailError2: "Proszę podać prawidłowy adres email",
      formSubjectError1: "Proszę podać temat",
      formSubjectError2: "Temat nie może być krótszy niż 2 litery",
      formMessageError1: "Proszę napisać wiadomość",
      formMessageError2: "Wiadomość nie może byc krótsza niż 2 litery",
      //right column
      email: "Adres email",
      phones: "Telefon",
      address: "Adres",
    },

    footer: {
      linksTitle: "Szybkie linki",
      infoTitle: "Informacja",
      contactsTitle: "Kontakty",
    },

    cookies: {
      header: "Polityka Cookies",
      paragraph:
        'Witryna korzysta z plików cookies, dzięki którym może działać lepiej. Stosujemy je dla ułatwienia korzystania z naszych stron, pomiaru, tworzenia statystyk, poprawy funkcjonalności strony. Prosimy o dokonanie stosownych wyborów dotyczących preferencji co do funkcjonowania plików cookies. Klikając "Odrzucam cookies", automatycznie odrzucisz stosowanie wszystkich technologii, poza technicznie niezbędnymi. Klikając "Dostosuj zgody", możesz w dalszym kroku indywidualnie dostosować swoje preferencje co do cookies opcjonalnych. Poprzez kliknięcie "Akceptuję cookies", wyrażasz zgodę na wykorzystywanie technologii cookies zgodnie z ustawieniami Twojej przeglądarki.',
      acceptBtn: "Akceptuję cookies",
      rejectBtn: "Odrzucam cookies (poza niezbędnymi)",
      detailsBtn: "Dostosuj zgody",
      functionalCheckbox: "Akceptuję funkcjonalne cookies",
      analyticalCheckbox: "Akceptuję analityczne cookies",
      marketingCheckbox: "Akceptuję reklamowe cookies",
      saveBtn: "Zapisz zmiany",
    },

    consent: {
      consentLink: "Informacja o przetwarzaniu danych osobowych dostępna ",
      consentLinkText: "TUTAJ",
    },
  },
};
