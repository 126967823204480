import React from "react";
import { Link } from "react-router-dom";
import Checkbox from "react-custom-checkbox";
import { check } from "../../img";
import { consentPL, consentRU } from "../../files";
import { langContent } from "../../languages";

const ConsentCheckboxes = ({ language, consent, setConsent }) => {
  //language check
  let lang = langContent;
  if (language === "Polish") {
    lang = langContent.Polish;
  } else if (language === "English") {
    lang = langContent.English;
  } else {
    lang = langContent.Russian;
  }

  return (
    <>
      <Checkbox
        borderColor="rgb(218, 0, 38)"
        checked={consent.data}
        className="consent-label"
        containerStyle={{ cursor: "pointer", marginBottom: ".5rem" }}
        icon={<img src={check} alt="data-checkbox" className="consent-img" />}
        label={lang.index.formConsentData}
        onChange={() =>
          setConsent((prev) => {
            return { ...prev, data: !prev.data };
          })
        }
        size={25}
        style={{
          background: consent.data ? "rgb(218, 0, 38)" : "transparent",
          marginRight: "10px",
        }}
      />
      <Checkbox
        borderColor="rgb(218, 0, 38)"
        checked={consent.doc}
        className="consent-label"
        containerStyle={{ cursor: "pointer" }}
        icon={<img src={check} alt="data-checkbox" className="consent-img" />}
        label={lang.index.formConsentDoc}
        onChange={() =>
          setConsent((prev) => {
            return { ...prev, doc: !prev.doc };
          })
        }
        size={25}
        style={{
          background: consent.doc ? "rgb(218, 0, 38)" : "transparent",
          marginRight: "10px",
        }}
      />
      <label style={{ marginLeft: "40px" }}>
        {lang.index.formConsentLink}
        <Link
          style={{ color: "rgb(218, 0, 38)" }}
          target="blank"
          to={language === "Polish" ? consentPL : consentRU}
        >
          {lang.index.formConsentLinkText}
        </Link>
        .
      </label>
    </>
  );
};

export default ConsentCheckboxes;
