import React from "react";
import { Link } from "react-router-dom";
import { langContent } from "../../languages";

const ConsentLink = ({ docLink, language, style }) => {
  let lang = langContent;
  if (language === "Polish") {
    lang = langContent.Polish;
  } else if (language === "English") {
    lang = langContent.English;
  } else {
    lang = langContent.Russian;
  }

  return (
    <div style={{ ...style }}>
      <p>
        {lang.consent.consentLink}
        <Link to={docLink} target="blank" style={{ color: "rgb(218, 0, 38)" }}>
          {lang.consent.consentLinkText}
        </Link>
        .
      </p>
    </div>
  );
};

export default ConsentLink;
