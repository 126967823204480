import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    ::-moz-selection {
    background: #DA0026;
    color: white;
    }
    ::-webkit-selection {
    background: #DA0026;
    color: white;
    }
    ::selection {
    background: #DA0026;
    color: white;
    }
}

button {
    cursor: pointer;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 1rem 1.5rem;
    border: 2px solid #DA0026;
    background: #DA0026;
    transition: all .3s;
    &:hover {
        background: transparent;
        border-color: white;
    }
}

h1 {
    font-size: 4rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    padding: 2rem;
    line-height: 1.2;
}

h2  {
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
    }

h3 {
    color: white;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
}

h4 {
    color: white;
    font-weight: 500;
    line-height: 1.2;
}

p {
    color: rgb(33, 37, 41);
    line-height: 1.7;
    font-weight: 300;
    font-size: 1.1rem;
    text-align: justify;
    @media all and (max-width: 1366px) {
    font-size: 1rem;
}
}

a {
    color: white;
    text-decoration: none;
    &:hover {
        color: rgb(218, 0, 38);
    }
}
`;

export default GlobalStyles;
