import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledServices = styled(motion.section)`
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  @media all and (max-width: 768px) {
    padding: 2.5rem 0;
  }
`;

export const StyledContainer = styled.div`
  width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-weight: 700;
    color: rgb(218, 0, 38);
    position: relative;
    margin-bottom: 3rem;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%, 3rem);
      width: 10rem;
      height: 0.25rem;
      background: rgb(218, 0, 38);
    }
  }
  .offer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        flex: 1;
        height: 7rem;
        margin: 0 1rem;
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: 2px;
        border-radius: 1rem;
        :hover {
          color: black;
          border-color: black;
        }
      }
    }
  }
  @media all and (max-width: 1600px) {
    width: 1000px;
  }
  @media all and (max-width: 1144px) {
    width: 90vw;
  }
  @media all and (max-width: 768px) {
    width: 95vw;
    h2 {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      &:after {
        width: 5rem;
        transform: translate(-50%, 2.5rem);
      }
    }
    p,
    li {
      font-size: 1rem;
    }
    .offer-container {
      ul {
        flex-direction: column;
        button {
          width: 100%;
          margin: 0.5rem 0;
        }
      }
    }
  }
`;

export const StyledVacancies = styled(motion.section)`
  padding: 4rem 0;
  background: #f8f9fa;
  display: flex;
  justify-content: center;
  h3 {
    color: rgb(218, 0, 38);
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 1.5rem;
    color: rgb(218, 0, 38);
    text-align: center;
  }
  .vacancies-container {
    margin-top: 2rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .vacancy {
        cursor: pointer;
        width: 30%;
        height: 20rem;
        margin-bottom: 6.5rem;
        h4 {
          transition: all 0.3s ease-in-out;
        }
        .img-container {
          * {
            transition: all 0.3s;
          }
          margin-bottom: 1rem;
          width: 100%;
          height: 100%;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 12.5rem 1rem 12.5rem 1rem;
          }
        }
        &:hover {
          h4 {
            transform: scale(1.2);
          }
          img {
            border-radius: 1rem;
          }
        }
      }
    }
  }
  .guarantees {
    margin: 1rem 0 4rem 0;
    li {
      padding: 0 2rem;
      line-height: 1.5;
      color: #4d4d4d;
      font-weight: 300;
      font-size: 1.2rem;
      text-align: justify;
      list-style-type: none;
      margin-bottom: 1rem;
      &:before {
        content: "✓";
        color: rgb(218, 0, 38);
        font-weight: bold;
        display: inline-block;
        width: 1em;
        font-size: 20px;
        margin-left: -1em;
      }
    }
  }
  @media all and (max-width: 1144px) {
    .vacancies-container {
      padding: 0 0 2rem 0;
    }
  }
  @media all and (max-width: 768px) {
    h4 {
      font-size: 1.3rem;
    }
    .guarantees {
      li {
        font-size: 1rem;
      }
    }
    .vacancies-container {
      align-items: center;
    }
    .vacancies-container .row {
      flex-direction: column;
      width: 85%;
      .vacancy {
        width: 100%;
        height: 15rem;
        margin-bottom: 6rem;
      }
    }
  }
`;

export const StyledInfo = styled(motion.section)`
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  p {
    line-height: 1.7;
    color: #4d4d4d;
    font-weight: 300;
    font-size: 1.2rem;
    text-align: justify;
  }
  .line {
    display: block;
    width: 50%;
    height: 0.3rem;
    margin: 2rem auto;
    background: rgb(218, 0, 38);
    border-radius: 2rem;
  }
  .info-container {
    display: flex;
    width: 100%;
    overflow: hidden;
    .left,
    .right {
      flex: 1;
    }
    a {
      color: rgb(218, 0, 38);
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      margin-bottom: 1rem;
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        width: 55%;
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: 0.2em;
        line-height: 2;
        padding: 1.25rem;
        border: 3px solid rgb(218, 0, 38);
        border-radius: 1rem;
        background-color: rgb(218, 0, 38);
        color: white;
        text-align: center;
        transition: all 0.2s;
        &:hover {
          background: transparent;
          border-color: black;
          color: black;
          text-decoration: none;
        }
      }
    }
  }
  @media all and (max-width: 1144px) {
    padding: 2rem 0;
    .info-container {
      .right {
        a {
          width: 90%;
        }
      }
    }
  }
  @media all and (max-width: 768px) {
    p {
      font-size: 1rem;
    }
    .info-container {
      flex-flow: column;
    }
  }
`;

export const StyledLegalization = styled.section`
  display: flex;
  justify-content: center;
  background: #f8f9fa;
  padding: 4rem 0;
  p {
    line-height: 1.5;
    color: #4d4d4d;
    font-weight: 300;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  li {
    padding: 0 2rem;
    line-height: 1.5;
    color: #4d4d4d;
    font-weight: 300;
    font-size: 1.2rem;
    text-align: justify;
    list-style-type: none;
    margin-bottom: 0.5rem;
    &:before {
      content: "✓";
      color: rgb(218, 0, 38);
      font-weight: bold;
      display: inline-block;
      width: 1em;
      font-size: 20px;
      margin-left: -1em;
    }
  }
  @media all and (max-width: 768px) {
    p,
    li {
      font-size: 1rem;
    }
  }
`;

export const StyledBusiness = styled(StyledLegalization)`
  background: white;
  p {
    margin: 0;
  }
  ul {
    margin: 2rem 0;
  }
`;
