import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//styles and images
import styled from "styled-components";
import { bg } from "../../img";
//animations
import { motion } from "framer-motion";
import { SlideAnimation } from "../../styles/Animations";
//components
import Logo from "./Logo";
import ScrollBtn from "./ScrollBtn";
//languages
import { langContent } from "../../languages";

const Header = (props) => {
  const location = useLocation();
  const [header, setHeader] = useState("");
  let lang = langContent;

  useEffect(() => {
    if (location.pathname === "/employers") {
      setHeader(lang.employers.header);
    } else if (location.pathname === "/employees") {
      setHeader(lang.employees.header);
    } else if (location.pathname === "/contact") {
      setHeader(lang.contact.header);
    }
  }, [location, lang]);

  if (props.language === "Polish") {
    lang = langContent.Polish;
  } else if (props.language === "English") {
    lang = langContent.English;
  } else {
    lang = langContent.Russian;
  }

  return (
    <>
      <StyledHead>
        <ScrollBtn />
        <StyledMainContainer>
          <Logo />
          <div className="title-container">
            <motion.h1 variants={SlideAnimation}>
              <pre>{header}</pre>
            </motion.h1>
          </div>
        </StyledMainContainer>
      </StyledHead>
    </>
  );
};

const StyledHead = styled.section`
  height: 75vh;
  display: flex;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("${bg}") fixed top center no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
  color: white;
  overflow: hidden;
`;

const StyledMainContainer = styled.div`
  min-height: 100%;
  width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  .title-container {
    margin-top: 10rem;
    border-top: 2px solid white;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media all and (max-width: 2000px) {
    .title-container {
      height: 30%;
      margin-top: 3rem;
    }
  }
  @media all and (max-width: 1600px) {
    width: 1000px;
    h1 {
      font-size: 3rem;
      padding: 1rem 0;
    }
  }
  @media all and (max-width: 1144px) {
    width: 90vw;
  }
  @media all and (max-width: 768px) {
    width: 95vw;
    h1 {
      font-size: 2rem;
    }
  }
`;

export default Header;
